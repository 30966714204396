import { Container } from "@mui/material";
import { FC } from "react";



export const Imprint: FC = () => {
    return (
        <Container>
            <br></br>
            <br></br>
            <br></br>
            <h1>Impressum</h1>
            <br></br>
            <p>Verantwortlich für dieses Angebot gemäß § 5 TMG:</p>
            <p>Die katholische Pfarrjugend Neusäß ist ein nicht-rechtsfähiger Verein bestehend aus Jugendlichen der
                Pfarreinengemeinschaft Neusäß.
                Sie werden vertreten durch den Vorstand der Kirchenverwaltung, Herrn Pfarrer Spiegel
            </p>
            <br></br>
            <p>Zentrales Pfarrbüro der Katholischen Pfarrjugend Neusäß</p>
            <p>Gregor-Mendel-Str. 1</p>
            <p>86356 Neusäß</p>
            <p>Telefon: +49 8214190320</p>
            <p>Email: pg.neusaess@bistum-augsburg.de</p>
            <p>Internet: www.pgneusaess.de</p>
            <br></br>
            <h3>Inhaltlich verantwortlich gemäß § 18 Absatz 2 MStV:</h3>
            <p>Herr Pfarrer Stephan Spiegel</p>
            <p>Gregor-Mendel-Str. 1</p>
            <p>86356 Neusäß</p>
            <p>Telefon: +49 8214190320</p>
            <p>Email: stephan.spiegel@bistum-augsburg.de</p>
            <br></br>
            <p>Zuständige kirchliche Stiftungsaufsichtsbehörde</p>
            <br></br>
            <p>Diozöse Augbsurg</p>
            <p>Bischöfliche Finanzkammer</p>
            <p>Fronhof 4</p>
            <p>86152 Augsburg</p>
            <br></br>
            <p>Telefon: 0821 3166-0</p>
            <p>E-Mail:  info@bistum-augsburg.de</p>

            <h3>Allgemeiner Hinweis zu den Bildquellen auf dieser Seite</h3>
            <p>Das Bildmaterial auf diesem Internetauftritt wurde persönlich von den Verantwortlichen für die Umsetzung erstellt.
                Die Personen auf den Bildern haben schriftlich zugestimmt, dass die Bilder veröffentlich werden dürfen!
            </p>
            <br></br>
            <br></br>

            <h3>Hinweis zur Problematik von externen Links:</h3>
            <p>Die Kath. Pfarrjugend Neusäß, Gregor-Mendel-Str. 1 86356 Neusäß (nachfolgend Kath. Pfarrkirchenstiftung genannt) ist als Inhaltsanbieter nach § 7 Abs.1 Telemediengesetz für
                die “eigenen Inhalte”, die er zur Nutzung bereithält, nach den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise (“Links”) auf
                die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Durch den Querverweis haltet die Kath. Pfarrkirchenstiftung insofern “fremde Inhalte”
                zur Nutzung bereit, die in dieser Weise gekennzeichnet sind: Bei “Links” handelt es sich stets um “lebende”
                (dynamische) Verweisungen. Die aufgeführte Kath. Pfarrkirchenstiftung hat bei der erstmaligen Verknüpfung zwar den
                fremden Inhalt daraufhin überprüft, ob durch ihn eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird.
                Sie überprüfen aber die Inhalte, auf die sie in ihrem Angebot verweisen, nicht ständig auf Veränderungen, die eine Verantwortlichkeit neu begründen
                könnten. Wenn sie feststellen oder von anderen darauf hingewiesen wird, dass ein konkretes Angebot, zu dem sie einen Link bereitgestellt haben,
                eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, werden sie den Verweis auf dieses Angebot aufheben.

            </p>
            <br></br>
            <br></br>

            <h3>Urheberrecht</h3>
            <p>Sämtliche auf www.pfarrjugendneusaess.de veröffentlichten Inhalte sind urheberrechtlich oder durch sonstige Rechte geschützt. Die Nutzungsrechte stehen den aufgeführten Kath.
                Pfarrkirchenstiftungen, den Gruppen, Organisationen und Initiativen in den Pfarreien oder ihren Lizenzgebern zu. Ohne vorherige schriftliche Genehmigung sind
                Nutzungen der Inhalte insgesamt oder in Teilen unzulässig. Die Genehmigung der Nutzung kann bei den Rechteinhabern erbeten werden. Eine Genehmigung reicht nur
                so weit, wie den Kath. Pfarrkirchenstiftungen, ihren Gruppen, Organisationen und Initiativen Rechte übertragen wurden.
            </p>
            <br></br>
            <br></br>

            <h3>Haftungsausschluss für unberechtigt geltend gemachte Kosten:</h3>
            <p>Wir sind um die urheberrechtliche Richtigkeit unserer Internetseiten bemüht und greifen nur auf selbst erstellte Inhalte sowie auf lizenzierte und
                lizenzfreie Werke zurück. Sollten dennoch Inhalte oder Aufmachung der Internetseite Rechte Dritter oder gesetzliche Vorschriften verletzen, bitten
                wir um umgehende Mitteilung an den im Impressum genannten Verantwortlichen. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                umgehend entfernen.
            </p>
            <br></br>
            <br></br>

            <h1>Datenschutzerklärung</h1>
            <h3>Name und Anschrift des Verantwortlichen</h3>
            <p>Zentrales Pfarrbüro der Katholischen Pfarrjugend Neusäß</p>
            <p>Gregor-Mendel-Str. 1</p>
            <p>86356 Neusäß</p>
            <p>Telefon: +49 8214190320</p>
            <p>Email: pg.neusaess@bistum-augsburg.de</p>
            <p>Internet: www.pgneusaess.de</p>
            <br></br>

            <h3>Betriebliche Datenschutzbeauftragte</h3>
            <p>Diozöse Augsburg</p>
            <p>Abteilung Datenschutz</p>
            <p>Fronhof 4</p>
            <p>86152 Augsburg</p>
            <p>Telefon: 0821 / 3166 – 8383</p>
            <p>Email: datenschutz@bistum-augsburg.de</p>
            <br></br>

            <h3>Datenschutzbeauftragter der Bayer. (Erz-)Diözesen (Datenschutzaufsicht):</h3>
            <p>Herr Jupp Joachimski</p>
            <p>Kapellenstraße 4</p>
            <p>80333 München</p>
            <p>Telefon: 089/2137-1796</p>
            <p>Telefax: 089/2137-1585</p>
            <p>Email: jjoachimski@eomuc.de</p>
            <a href="https://www.katholisches-datenschutzzentrum.de/wp-content/uploads/2018/06/KDG-Beschlussfasssung-der-Vollversammlung-der-BK-vom-20.11.2017.pdf">Datenschutzbestimmungen (pdf / 336 kb)</a>
            <br></br>
            <br></br>
            <h3>§ 1 Information über die Erhebung personenbezogener Daten</h3>
            <p>(1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind alle Daten,
                die auf Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p>
            <p>(2) Verantwortlicher gem. § 4 Nr. 9 des Gesetzes über den Kirchlichen Datenschutz (KDG) ist [Abteilung Datenschutz, Fronhof 4 86152 Augsburg, datenschutz@bistum-augsburg.de]
                (siehe unser Impressum). Unseren Datenschutzbeauftragten erreichen Sie unter [datenschutz@bistum-augsburg.de] oder unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.</p>
            <p>
                (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf.
                Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir,
                nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
            </p>
            <p>
                (4) Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten,
                werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
            </p>
            <br></br>
            <h3>§ 2 Ihre Rechte</h3>
            <p>(1) Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>

            <ul>
                <li>Recht auf Auskunft</li>
                <li>Recht auf Berichtigung oder Löschung</li>
                <li>Recht auf Einschränkung der Verarbeitung</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung</li>
                <li>Recht auf Datenübertragbarkeit</li>
            </ul>
            <p>
                (2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.
            </p>
            <p>
                Die ausführlichen Rechte als „Betroffene/r“ finden sie unter <a href="https://www.bistum-augsburg.de/Generalvikariat-Zentrale-Dienste/Datenschutz#Betroffenenrechte">
                    www.bistum-augsburg.de/Generalvikariat-Zentrale-Dienste/
                    Datenschutz#Betroffenenrechte</a> (externer Link )

            </p>
            <br></br>
            <h3>§ 3 Erhebung personenbezogener Daten bei Besuch unserer Website</h3>
            <p>
                (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln,
                erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten,
                erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit
                zu gewährleisten (Rechtsgrundlage ist § 6 Abs. 1 lit. g KDG):
            </p>
            <ul>
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit der Anfrage</li>
                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Inhalt der Anforderung (konkrete Seite)</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>jeweils übertragene Datenmenge</li>
                <li>Website, von der die Anforderung kommt</li>
                <li>Browser</li>
                <li>Betriebssystem und dessen Oberfläche</li>
                <li>Sprache und Version der Browsersoftware</li>
            </ul>
            <p>
                (2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert.
                Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet
                gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen.
                Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt
                nutzerfreundlicher und effektiver zu machen.
            </p>
            <p>
                (3) Einsatz von Cookies:
            </p>
            <ol>
                <li>Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden: Transiente Cookies (dazu 2), Persistente Cookies (dazu 3).</li>
                <li>Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies.
                    Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen.
                    Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich
                    ausloggen oder den Browser schließen.</li>
                <li>Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann.
                    Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</li>
                <li>Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme
                    von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht
                    alle Funktionen dieser Website nutzen können.</li>
            </ol>
            <h3>§ 4 Weitere Funktionen und Angebote unserer Website</h3>
            <p>
                (1) Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, die Sie bei Interesse nutzen können.
                Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die
                zuvor genannten Grundsätze zur Datenverarbeitung gelten.
            </p>
            <p>
                (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt,
                sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.
            </p>
            <p>
                (3) Weiterhin können wir Ihre personenbezogenen Daten an Dritte weitergeben, wenn Aktionsteilnahmen, Gewinnspiele,
                Vertragsabschlüsse oder ähnliche Leistungen von uns gemeinsam mit Partnern angeboten werden. Nähere Informationen
                hierzu erhalten Sie bei Angabe Ihrer personenbezogenen Daten oder in der Beschreibung des Angebotes.
            </p>
            <p>
                (4) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat außerhalb des Europäischen Wirtschaftsraumen (EWR) haben,
                informieren wir Sie über die Folgen dieses Umstands in der Beschreibung des Angebotes.
            </p>
            <br></br>
            <h3>§ 5 Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h3>
            <p>(1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher
                Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.</p>
            <p>(2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen,
                können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht
                zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt
                wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns
                durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung
                einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</p>
            <br></br>
            <h3>§ 6 Weitere Informationen</h3>
            <p>Unsere Website wurde mit Hilfe der No-Sql-Datenbank MongoDB erstellt. Nähere Informatioen erhalten Sie unter <a href="https://www.mongodb.com/de-de/legal/privacy-policy">https://www.mongodb.com/de-de/legal/privacy-policy</a></p>
         
            <br></br>
            <br></br>
            <br></br>
        </Container>
    );
}