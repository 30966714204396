import axios from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { CreateDownloadDTO, DownloadDTO } from "../models";


export const getDownloads = async (): Promise<DownloadDTO[]> => {
    return await axios.get('Download/GetDownloads', {
        baseURL: process.env.REACT_APP_ENDPOINT_URL,
    })
        .then(response => {
            return response.data as DownloadDTO[];
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const download = async (id: string, token: string): Promise<string> => {
    return await axios.get('Download/Download/' + id + "/" + token, {
        baseURL: process.env.REACT_APP_ENDPOINT_URL,
        timeout: Infinity,
        responseType: "text"
    }).then(response => {
        return response.data as string;
    }).catch(reason => {
        if (reason.response !== undefined) {
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        } else {
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}

export const deleteDownload = async (id: string): Promise<void> => {
    await axios.delete('Download/DeleteDownload/' + id, {
        baseURL: process.env.REACT_APP_ENDPOINT_URL
    })
        .catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}


export const uploadChunk = async (chunk: any, counter: number, fileGuid: any) => {
    return await axios.post("Download/UploadChunks", chunk, {
        baseURL: process.env.REACT_APP_ENDPOINT_URL,
        params: {
            id: counter,
            fileName: fileGuid,
        }
    }).then(response => {
        return response
    }).catch(reason => {
        if (reason.response !== undefined) {
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        } else {
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}


export const authenticateDownload = async (id: string, password: string) => {
    var formData = new FormData();
    formData.append("id", id);
    formData.append("password", password);
    return await axios.post("Download/AuthenticateDownload", formData, {
        baseURL: process.env.REACT_APP_ENDPOINT_URL
    })
        .then(response => {
            return response
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const uploadComplete = async (fileGuid: any, formData: FormData) => {
    return await axios.post("Download/UploadComplete", formData, {
        baseURL: process.env.REACT_APP_ENDPOINT_URL,
        params: {
            fileName: fileGuid,
        },
        timeout: Infinity,
    }).then(response => {
        return response
    }).catch(reason => {
        if (reason.response !== undefined) {
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        } else {
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}