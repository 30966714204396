import { Route, Routes } from 'react-router-dom';
import './App.css';
import Events from './pages/Events';
import { Home } from './pages/Home';
import Nikolaus from './pages/Nikolaus';
import { FC, Fragment, useEffect } from 'react';
import SignUp from './pages/SignUp';
import SignIn from './pages/Login';
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import AppContext from './context/context';
import { MainLayout } from './pages/MainLayout';
import { User } from './componets/User';
import { Zeltlager } from './pages/Zeltlager';
import ForgotPassword from './pages/ForgotPassword';
import { Downloads } from './pages/Downloads';
import { AdminDashboard } from './pages/AdminDashboard';
import { LeiterDashboard } from './pages/LeiterDashboard';
import { toast } from 'react-toastify';
import { Spielewochenende } from './pages/Spielewochenende';
import { Imprint } from './pages/Imprint';
import { GruppenStunden } from './pages/GruppenStunden';

export const App: FC = (props) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4CBB17'
      }
    }
  });

  const mediaquery = useMediaQuery('(max-width: 700px)')

  useEffect(() => {
    if(mediaquery){
      toast.info("Wir empfehlen die Seite an einem PC/Tablet zu nutzen!")
    }
  }, [])
  

  return (
    <div>
      <Fragment>
        <CssBaseline>
          <ThemeProvider theme={theme}>
          <AppContext>
            <MainLayout>
              <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/termine" element={<Events></Events>}></Route>
                <Route path='/nikolaus' element={<Nikolaus></Nikolaus>}></Route>
                <Route path='/spielewochenende' element={<Spielewochenende></Spielewochenende>}></Route>
                <Route path="/signup" element={<SignUp></SignUp>}></Route>
                <Route path="/login" element={<SignIn></SignIn>}></Route>
                <Route path="/user" element={<User></User>}></Route>
                <Route path="/forgotPassword" element={<ForgotPassword></ForgotPassword>}></Route>
                <Route path="/downloads" element={<Downloads></Downloads>}></Route>
                <Route path="/adminDashboard" element={<AdminDashboard></AdminDashboard>}></Route>
                <Route path="gruppenstunden" element={<GruppenStunden></GruppenStunden>}></Route>
                <Route path="/zeltlager" element={<Zeltlager></Zeltlager>}></Route>
                <Route path="/impressum" element={<Imprint></Imprint>}></Route>
              </Routes>
            </MainLayout>
          </AppContext>
          </ThemeProvider>
        </CssBaseline>
      </Fragment>
    </div>
  );
}
export default App;
