import { resolveTypeReferenceDirective } from "typescript";
import axiosInstance from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { ZeltlagerDTO } from "../models";

export const getZeltlager = async (): Promise<ZeltlagerDTO> => {
    return await axiosInstance.get('Zeltlager/GetZeltlager')
    .then(response => {
        return response.data as ZeltlagerDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const createZeltlager = async (zeltlager: ZeltlagerDTO): Promise<ZeltlagerDTO> => {
    return await axiosInstance.post('Zeltlager/CreateZeltlager', zeltlager)
    .then(response => {
        return response.data as ZeltlagerDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const deleteZeltlager = async (): Promise<void> => {
    await axiosInstance.delete('Zeltlager/DeleteZeltlager')
    .catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const updateZeltlager = async (zeltlager: ZeltlagerDTO): Promise<ZeltlagerDTO> => {
    return await axiosInstance.put('Zeltlager/UpdateZeltlager', zeltlager)
    .then(response => {
        return response.data as ZeltlagerDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const downloadBookingsAsPDF = async () => {
    return await axiosInstance.get('Zeltlager/GetPDFsOfZeltlager', {
        timeout: Infinity,
        responseType: 'blob'
    }).then(response => {
        return new Blob([response.data], {
            type: response.headers.getContentType as string
            });
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
    });
}

export const sendReminderEmail =async () => {
    return await axiosInstance.get('Zeltlager/SendReminderEmail')
        .then(response => {
            return response;
        })
        .catch(reason => {
            if(reason.response !==undefined){
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            }else{
                ErrorNotification(undefined, reason.message)
            }
        });
}