import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { FC } from "react"
import { DownloadsAdmin } from "../componets/Admin/DownloadsAdmin";
import { EventsAdmin } from "../componets/Admin/EventsAdmin";
import { NikolausAdmin } from "../componets/Admin/NikolausAdmin";
import { SpielewochenendeAdmin } from "../componets/Admin/SpielewochenendeAdmin";
import { TextVerwaltungAdmin } from "../componets/Admin/TextVerwaltungAdmin";
import { ZeltlagerAdmin } from "../componets/Admin/ZeltlagerAdmin";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export const AdminDashboard: FC = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const mediaquery = useMediaQuery('(max-width: 700px)')

    return (
        <>
            <br></br>
            <br></br>
            <div style={{ marginLeft: mediaquery ? '2%' : '15%', marginRight: mediaquery ? '2%' : '15%' }}>
                <h1>Admin Dashboard</h1>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Admintabs" allowScrollButtonsMobile variant="scrollable" scrollButtons={"auto"}>
                            <Tab label="Termine" {...a11yProps(0)} />
                            <Tab label="Nikolausaktion" {...a11yProps(1)} />
                            <Tab label="Zeltlager" {...a11yProps(2)} />
                            <Tab label="Spielewochenende" {...a11yProps(3)} />
                            <Tab label="Downloads" {...a11yProps(4)} />
                            <Tab label="Leiter" {...a11yProps(5)} />
                            <Tab label="Text-Verwaltung" {...a11yProps(6)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <EventsAdmin></EventsAdmin>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <NikolausAdmin></NikolausAdmin>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ZeltlagerAdmin></ZeltlagerAdmin>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <SpielewochenendeAdmin></SpielewochenendeAdmin>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <DownloadsAdmin></DownloadsAdmin>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <TextVerwaltungAdmin></TextVerwaltungAdmin>
                    </TabPanel>
                </Box>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </>
    )
}