import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { EventDTO } from "../models";
import * as service from "../services/EventService"


interface EventContextInterface {
    loading: boolean,
    getEvents: () => Promise<EventDTO[]>
    createEvent: (event: EventDTO) => Promise<EventDTO>
    deleteEvent: (eventId: string) => Promise<void>
    downloadICSFile: () => Promise<Blob>
    setLoading: Dispatch<SetStateAction<boolean>>
  }
  
  
  const Context = React.createContext<EventContextInterface>({
    loading: false,
    getEvents: () => new Promise<EventDTO[]>(() => {}),
    createEvent: (event: EventDTO) => new Promise<EventDTO>(() => {}),
    deleteEvent: (eventId: string) => new Promise<void>(() => {}),
    downloadICSFile: () => new Promise<Blob>(() => {}),
    setLoading: () => {}
  });
const EventContext: FC<{ children: ReactNode }> = (props) => {

    const [loading, setLoading] = useState(false)

    const getEvents = async (): Promise<EventDTO[]> => {
        setLoading(true)
        const response = await service.getEvents();
        return response as EventDTO[]
    }

    const downloadICSFile = async (): Promise<Blob> => {
      setLoading(true)
      const response = await service.downloadICSFile();
      return response
  }

    const createEvent = async (event: EventDTO): Promise<EventDTO> => {
        setLoading(true)
        const response = await service.createEvent(event);
        return response as EventDTO
    }

    const deleteEvent = async (eventId: string): Promise<void> => {
        setLoading(true)
        const response = await service.deleteEvent(eventId);
        return response;
    }

    return (
        <Context.Provider
          value={{
            loading,
            setLoading,
            getEvents,
            createEvent,
            deleteEvent,
            downloadICSFile
          }}>
          {props.children}
        </Context.Provider>
      );
}
export default EventContext;
  export const useEvent = () => React.useContext(Context)