import { Container } from '@mui/material'
import React from 'react'
import { ReadMore } from '../ReadMore'

type Props = {}

export default function SpieleWochenendeTexte({ }: Props) {
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <h1>Spielewochenende</h1>
            <br /><br />
            <h4>Spielewochenende 2022</h4>
            <ReadMore>
                Am 25.11.2022 trafen sich die Leiter der Pfarrjugend Neusäß gegen 15:30 Uhr zum Spielewochenende in Oberliezheim. Nach einer Zeit trudelten auch die zehn Mädels sowie
                sechs Jungs in das gemütliche Häuschen ein und durften auch direkt ihre Gemeinschaftszimmer beziehen, nach einem freudigen Wiedersehen trafen sich dann alle Teilnehmer
                im großen Aufenthaltsraum zu einer Begrüßungsrunde, die mit Kennenlernspielen verstärkt wurde. Anschließend wurden direkt im Stuhlkreis Gemeinschaftsspiele wie
                „Gekreuzt oder gerade?“, „Stapelspiel“ oder „Armer schwarzer Kater“ gespielt, in dieser Zeit bereitete ein Teil der Leiter das Abendessen vor. Über die Kässpatzen haben
                sich alle riesig gefreut! Am Abend wurden dann wieder Gemeinschaftsspiele gespielt und in der Küche fleißig aufgeräumt. Anschließend gab es noch einen kleinen Moment
                der Ruhe mit einem Gebet, woraufhin die Kinder dann ins Bett gingen und den Abend ausklingen ließen.
                Am nächsten Morgen gab es gegen 10 Uhr Frühstück. Dann durften sich die Kinder selbst überlegen, wie sie ihre Zeit verbringen wollten, somit spielten die Kinder
                gemeinsam Spiele wie „Werwolf“, „Boogle“, „Wiszard“, „SkipBo“, „Skyjo“ oder auch draußen Tischtennis, dabei wurde gemütlich Kinderpunsch getrunken und Lebkuchen
                gegessen. Mittags gab es dann Nudeln mit Gemüsesoße. Nach dem Mittagessen und nach dem Spülen konnten sich die Kinder wieder beschäftigen, bis wir uns dann gegen
                16 Uhr zu Gemeinschaftsspielen im Stuhlkreis trafen. In dieser Zeit bereiteten drei unserer Leiter das Abendessen vor, die Kinder sind mit großem Hunger über die
                Burger hergefallen, anschließend wurden wieder Gemeinschaftsspiele gespielt, sehr beliebt war auch das Spiel „Wer würde eher?“. Gegen 22 Uhr war dann wieder ein
                Moment der Ruhe und ein anschließendes Gebet, woraufhin die Kinder sich bettfertig machten und dann noch in ihren Zimmern leise unterhalten haben. Am Morgen des
                27.11.2022 stand nach dem Frühstück dann das große Aufräumen an, bevor die Kinder alle um 10:30 Uhr abgeholt wurden. Alle halfen zusammen und waren daher pünktlich
                zur Abholung fertig.

                Wir freuen uns auf das nächste Spielewochenende mit euch!

            </ReadMore>
        </Container>
    )
}