
import { Button, Container } from '@mui/material';
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom';
import HomeTexte from '../componets/Home/HomeTexte';
import TextSplitter from '../componets/TextSplitter';
import './css/slideShow.css'


export const Home: FC = () => {
  const navigate = useNavigate()

  const naivgateToTab = (route: string) => {
    navigate(route)
  }

  return (
    <Container>
      <br></br>
      <br></br>
      <br></br>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
        <p>Die Pfarrjugend ist Teil der PG Neusäß. </p><a href="https://pgneusaess.de/" target='_blank'>Website</a>
      </div>
      <HomeTexte />
      <div className='Slide-Container'>
        <TextSplitter />
      </div>

      <br /><br /><br />
    </Container >
  )
};
export default Home;