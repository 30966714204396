 import { setRef } from "@mui/material"
import axios from "axios"
import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react"
import { SpielewochenendeBookingDTO } from "../models"
import * as service from "../services/SpielewochenendeBookingService"

 interface SpielewochenendeBookingContextInterface {
    loading: boolean,
    createSpielewochenendeBooking: (booking: SpielewochenendeBookingDTO) => Promise<SpielewochenendeBookingDTO>
    updateSpielewochenendeBooking: (booking: SpielewochenendeBookingDTO) => Promise<SpielewochenendeBookingDTO>
    getSpielewochenendeBookingForUser: () => Promise<SpielewochenendeBookingDTO>
    deleteSpielewochenendeBookingAsAdmin: (id: string, emailText: string, childId: string) => Promise<void>
    getAllSpielewochenendeBookings: () => Promise<SpielewochenendeBookingDTO[]>
    deleteSpielewochenendeBooking: (id: string) => Promise<void>
    setLoading: Dispatch<SetStateAction<boolean>>
}

const Context = React.createContext<SpielewochenendeBookingContextInterface>({
    loading: false,
    createSpielewochenendeBooking: () => new Promise<SpielewochenendeBookingDTO>(() => {}),
    updateSpielewochenendeBooking: () => new Promise<SpielewochenendeBookingDTO>(() => {}),
    getSpielewochenendeBookingForUser: () => new Promise<SpielewochenendeBookingDTO>(() => {}),
    deleteSpielewochenendeBookingAsAdmin: () => new Promise<void>(() => {}),
    getAllSpielewochenendeBookings: () => new Promise<SpielewochenendeBookingDTO[]>(() => {}),
    deleteSpielewochenendeBooking: (id: string) => new Promise<void>(() => {}),
    setLoading: () => {}
})

const SpielewochenendeBookingContext: FC<{ children: ReactNode}> = (props) => {
    const [loading, setLoading] = useState(false)

    const createSpielewochenendeBooking =async (booking: SpielewochenendeBookingDTO): Promise<SpielewochenendeBookingDTO> => {
        setLoading(true)
        const response = await service.createSpielewochenendeBooking(booking)
        return response as SpielewochenendeBookingDTO
    }

    const updateSpielewochenendeBooking =async (booking: SpielewochenendeBookingDTO): Promise<SpielewochenendeBookingDTO> => {
        setLoading(true)
        const response = await service.updateSpielewochenendeBooking(booking)
        return response as SpielewochenendeBookingDTO
    }

    const getSpielewochenendeBookingForUser = async (): Promise<SpielewochenendeBookingDTO> => {
        setLoading(true)
        const response = await service.getSpielewochenendeBookingForUser()
        return response as SpielewochenendeBookingDTO
    }

    const deleteSpielewochenendeBookingAsAdmin =async (id:string, childId: string, emailText: string): Promise<void> => {
        setLoading(true)
        return await service.deleteSpielewochenendeBookingAsAdmin(id, childId, emailText)
    }

    const deleteSpielewochenendeBooking =async (id:string): Promise<void> => {
        setLoading(true)
        return await service.deleteSpielewochenendeBooking(id)
    }

    const getAllSpielewochenendeBookings = async (): Promise<SpielewochenendeBookingDTO[]> => {
        setLoading(true)
        const response = await service.getAllSpielewochenendeBookings()
        return response as SpielewochenendeBookingDTO[]
    }

    return (
        <Context.Provider
        value={{
            loading,
            setLoading,
            createSpielewochenendeBooking,
            updateSpielewochenendeBooking,
            getAllSpielewochenendeBookings,
            getSpielewochenendeBookingForUser,
            deleteSpielewochenendeBooking,
            deleteSpielewochenendeBookingAsAdmin
        }}>
            {props.children}
        </Context.Provider>
    )
}
export default SpielewochenendeBookingContext
export const useSpielewochenendeBooking = () => React.useContext(Context)