import axiosInstance from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { NikolausaktionBookingDTO } from "../models";

export const createNikolausaktionBooking = async (
  nikolausAktionBooking: NikolausaktionBookingDTO
): Promise<NikolausaktionBookingDTO> => {
  return await axiosInstance.post(
    "NikolausaktionBooking/CreateNikolausaktionBooking",
    nikolausAktionBooking
  ).then(response => {
    return response.data as NikolausaktionBookingDTO;
  }).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })
};

export const updateNikolausaktionBooking = async (
  nikolausAktionBooking: NikolausaktionBookingDTO
): Promise<NikolausaktionBookingDTO> => {
  return await axiosInstance.put(
    "NikolausaktionBooking/UpdateNikolausaktionBooking",
    nikolausAktionBooking
  ).then(response => {
    return response.data as NikolausaktionBookingDTO;
  }).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })
};

export const downloadBookingsAsPDF = async () => {
  return await axiosInstance.get(
    "NikolausaktionBooking/GetPDFsOfNikolausaktion",
    {
      timeout: Infinity,
      responseType: "blob",
    }
  ).then(response => {
    return new Blob([response.data], {
      type: response.headers.getContentType as string,
    });
  }).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })
};

export const getNikolausaktionBookingsForUser = async (): Promise<
  NikolausaktionBookingDTO[]
> => {
  return await axiosInstance.get(
    "/NikolausaktionBooking/GetNikolausaktionBookingsForUser"
  ).then(response => {
    return response.data as NikolausaktionBookingDTO[];
  }).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })
};

export const deleteNikolausaktionBooking = async (
  id: string
): Promise<void> => {
  await axiosInstance.delete(
    "NikolausaktionBooking/DeleteNikolausaktionBooking/" +
    id
  ).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })
};

export const deleteNikolausaktionBookingAsAdmin = async (id: string, emailText: string): Promise<void> => {
  var formData = new FormData();
  formData.append("emailText", emailText);

  await axiosInstance.post(
    "NikolausaktionBooking/DeleteNikolausaktionBookingAsAdmin/" +
    id,
    formData
  ).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })

};

export const getAllNikolausaktionBookings = async (): Promise<NikolausaktionBookingDTO[]> => {
  return await axiosInstance.get(
    "NikolausaktionBooking/GetAllNikolausaktionBookings"
  ).then(response => {
    return response.data as NikolausaktionBookingDTO[];
  }).catch(reason => {
    if (reason.response !== undefined) {
      ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
    } else {
      ErrorNotification(undefined, reason.message)
    }
    throw reason;
  })

};
