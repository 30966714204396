import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, MenuItem, Paper, Select, Slide, TextareaAutosize, TextField } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import Typography from "@mui/material/Typography"
import { flexbox } from "@mui/system"
import { time } from "console"
import React, { SetStateAction } from "react"
import { FC, useEffect, useState } from "react"
import { StringLiteralType } from "typescript"
import { useNikolausaktionBooking } from "../../context/NikolausaktionBookingContext"
import { useNikolausaktion } from "../../context/NikolausaktionContext"
import { Meeting, NikolausBookingFormState, NikolausChild } from "../../models"
import { Child } from "./Child"
import NikolausDaten from "./NikolausDaten"

interface Props {
    booking: NikolausBookingFormState
    children: NikolausChild[]
    index: number
    bookingId: string
    dates: string[]
    meetings: Meeting[]
    timeSpan: string
    handleDeleteBooking: (id: string) => void
    update: (booking: NikolausBookingFormState, children: NikolausChild[], id: string) => void
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const UpdateNikolausBooking: FC<Props> = (props) => {

    const [booking, setBooking] = useState<NikolausBookingFormState>(props.booking)
    const [children, setChildren] = useState<NikolausChild[]>(props.children)
    const [dates, setDates] = useState<string[]>(props.dates)
    const [meetings, setMeetings] = useState<Meeting[]>(props.meetings)
    const [timeSpan, setTimeSpan] = useState<Meeting[]>([])
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openUpdateTime, setOpenUpdateTime] = useState(false);
    const [nikolausAktionActive, setNikolausAktionActive] = useState<boolean>()
    const nikolausaktioncontext = useNikolausaktion();
    const [newDate, setNewDate] = useState<string>(booking.date!.toString())
    const [newTimeSpan, setNewTimeSpan] = useState<string>(props.timeSpan)

    useEffect(() => {
        nikolausaktioncontext.getNikolausaktion().then(result => {
            setNikolausAktionActive(result.active)
        })
    }, [])
    

    const handleClickOpen = () => {
        setOpenDeleteConfirm(true);
    };
    const handleClose = () => {
        setOpenDeleteConfirm(false);
    };
    const addPointToWellDone = (keyOfChild: number) => {
        setChildren(children.map((child, key) => key === keyOfChild ? { ...child, wellDone: [...child.wellDone, ""] } : child))
    }

    const addPointToToImprove = (keyOfChild: number) => {
        setChildren(children.map((child, key) => key === keyOfChild ? { ...child, toImprove: [...child.toImprove, ""] } : child))
    }


    const deleteBooking = () => {
        props.handleDeleteBooking(props.bookingId)
        setOpenDeleteConfirm(false);
    }

    const handleClickOpenTime = (date: string) => {
        setOpenUpdateTime(true)
    }

    const updateTime = () => {
        setOpenUpdateTime(false)
        setBooking({ ...booking, time: newTimeSpan, date: newDate })
        var time = timeSpan.filter(x => x.id == newTimeSpan)[0].timeSpan!;
        console.log(time)
        setNewTimeSpan(time)
        setTimeSpan([])
    }

    const handleCloseTime = () => {
        setOpenUpdateTime(false)
    }


    const addChild = () => {
        setChildren([...children, { name: '', age: 0, wellDone: [] as String[], toImprove: [] as String[] } as NikolausChild])
    }

    const setFilteredTimeSpans = (date: string) => {
        let tempMap = groupBy(meetings, x => x.date);
        var pattern = /(\d{2})\.(\d{2})\.(\d{4})/
        setTimeSpan(Array.from(tempMap.get(new Date(date.replace(pattern, '$3-$2-$1')).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }))!) as Meeting[])
    }

    function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
            var key = grouper(item)
            if (!store.has(key)) {
                store.set(key, [item])
            } else {
                store.get(key)!.push(item)
            }
            return store
        }, new Map<K, V[]>())
    }

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2>Ihre Nikolausbuchung Nr. {props.index + 1}</h2>
                <Button variant="contained" disabled={!nikolausAktionActive} onClick={() => handleClickOpen()}>Stornieren</Button>
            </Box>

            <br></br>
            <NikolausDaten booking={booking} disableButtons={!nikolausAktionActive} setBooking={setBooking}></NikolausDaten>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <Grid container xs={12}>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', padding: '5%' }}>
                    <Typography>{'Datum: ' + newDate + ' - ' + newTimeSpan}</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', padding: '5%' }}>
                    <Button variant="contained" onClick={() => handleClickOpenTime(booking.date!)} disabled={!nikolausAktionActive}>Datum/Uhrzeit aktualisieren</Button>
                </Grid>
            </Grid>
            <br></br>
            <hr></hr>
            <Container>
                <div>
                    <Child addPointToToImprove={addPointToToImprove} addPointToWellDone={addPointToWellDone} children={children} setChildren={setChildren} disableButtons={!nikolausAktionActive}></Child>
                </div>
            </Container>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={addChild} disabled={!nikolausAktionActive}>Kind hinzufügen</Button></Box>
            <br></br>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={() => props.update(booking, children, props.bookingId)}  disabled={!nikolausAktionActive}>Nikolausbuchung aktualisieren</Button></Box>
            <br></br>
            <br></br>
            <br></br>
            <hr style={{ border: "2px solid black" }}></hr>
            <Dialog
                open={openDeleteConfirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="deleteEvent"
                fullWidth
            >
                <DialogTitle>{"Stornieren einer Buchung"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="deleteEvent" sx={{ display: 'flex', flexDirection: 'column' }}>
                        Wollen Sie die Buchung wirklich stornieren
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Nein</Button>
                    <Button onClick={() => deleteBooking()}>Ja</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openUpdateTime}
                TransitionComponent={Transition}
                keepMounted
                onClose={updateTime}
                aria-describedby="deleteEvent"
                fullWidth
            >
                <DialogTitle>{"Datum/Uhrzeit aktualisieren"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="deleteEvent" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <InputLabel id="date">Datum</InputLabel>
                        <Select
                            labelId="date"
                            id="dateId"
                            label="Datum"
                            fullWidth
                            value={newDate}
                            onChange={(value) => {
                                if (!!value) {
                                    setNewDate(value.target.value)
                                    setFilteredTimeSpans(value.target.value)
                                    setNewTimeSpan("")
                                }
                            }}
                        >
                            {
                                dates.map(date => {
                                    return <MenuItem value={date}>{date}</MenuItem>
                                })
                            }
                        </Select>
                        <br></br>
                        <InputLabel id="time">Uhrzeit</InputLabel>
                        <Select
                            labelId="time"
                            id="timeId"
                            label="Uhrzeit"
                            value={newTimeSpan}
                            onChange={(value) => {
                                setNewTimeSpan(value.target.value)
                            }}
                            fullWidth
                        >
                            {
                                timeSpan.length > 0 ? (
                                    timeSpan.map(text => {
                                        return <MenuItem value={text.id}>{text.timeSpan}</MenuItem>
                                    })
                                ) : (<MenuItem></MenuItem>)
                            }
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTime}>Abbrechen</Button>
                    <Button onClick={() => updateTime()}>Aktualisieren</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
