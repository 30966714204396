import { toast } from "react-toastify";
import { AktionChildDTO, ZeltlagerBookingFormState } from "../models";

export function validateBookingZeltlager(zeltlagerBooking: ZeltlagerBookingFormState, children: AktionChildDTO[]) : boolean {
    if(
        zeltlagerBooking.address?.city !== null && zeltlagerBooking.address?.city !== undefined &&
        zeltlagerBooking.address.houseNumber !== null && zeltlagerBooking.address?.houseNumber !== undefined &&
        zeltlagerBooking.address?.plz !== null && zeltlagerBooking.address?.plz !== undefined &&
        zeltlagerBooking.address?.street !== null && zeltlagerBooking.address?.street !== undefined &&
        zeltlagerBooking.parent?.firstName !== null && zeltlagerBooking.parent?.firstName !== undefined &&
        zeltlagerBooking.parent?.lastName !== null && zeltlagerBooking.parent?.lastName !== undefined &&
        zeltlagerBooking.parent?.phoneNumber !== null && zeltlagerBooking.parent?.phoneNumber !== undefined 
        )
        {
            for(let index = 0; index < children.length; index++) {
                if(
                    children[index].firstName !== null && children[index].firstName !== undefined &&
                    children[index].lastName !== null && children[index].lastName !== undefined &&
                    children[index].birthday !== null && children[index].birthday !== undefined &&
                    children[index].gender !== null && children[index].gender !== undefined 
                ) {

                }else {
                    toast.warn("Bitte alle mit * gekennzeichneten Felder ausfüllen")
                    return false;
                }
            }
            return true
        }
        toast.warn("Bitte alle mit * gekennzeichneten Felder ausfüllen")
        return false
}
 