import { Button, Container, TextField } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import { Meeting } from '../../models'


interface Props {
    day: string | undefined,
    setDays: Dispatch<SetStateAction<Map<string | undefined, Meeting[]>>>
}
export const CreateMeetings: FC<Props> = (props) => {

    const [startTime, setStartTime] = useState<Dayjs | null>();
    const [endTime, setEndTime] = useState<Dayjs | null>();
    const [timeInterval,setTimeInterval] = useState<number>(30)
    const [amountOfTeams,setAmountOfTeams] = useState<number>(3)

    const createMeetings = () => {
        const meetings = [] as Meeting[]
        let tmpStartTime = startTime;
        while(tmpStartTime?.isBefore(endTime?.subtract(1,'minute'))){    
            meetings.push({amount: amountOfTeams.toString(), timeSpan: tmpStartTime.format('HH:mm') + ' - ' + tmpStartTime.add(timeInterval,'minute').format('HH:mm')})
            tmpStartTime = tmpStartTime.add(timeInterval,'minute')
        }
        props.setDays((prevDays) => {
            const newMap = new Map(prevDays)
            newMap.set(props.day,meetings)
            return newMap;
        })
    }
    return (
        <>
            <Container sx={{ display: 'flex', flexDirection: 'column', width: '40%', justifyContent: 'flex-start', marginLeft: '0' }}>
                <h5>{props.day}</h5>
                <br></br>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
                    <TimePicker
                        label="Startzeit"
                        value={startTime}
                        onChange={(value) => {
                            setStartTime(value)}}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <br></br>
                    <TimePicker
                        label="Endzeit"
                        value={endTime}
                        onChange={(value) => setEndTime(value) }
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <br></br>
                <TextField label="Zeitintervall in min" type={'number'} value={timeInterval} onChange={(change) => setTimeInterval(Number.parseInt(change.target.value))}></TextField>
                <br></br>
                <TextField label="Anzahl an Teams" type={'number'} value={amountOfTeams} onChange={(change) => setAmountOfTeams(Number.parseInt(change.target.value))}></TextField>
                <br></br>
                <Button variant="contained" onClick={() => createMeetings()}>Termine anlegen</Button>
            </Container>
            <hr></hr>
        </>
    )
}

