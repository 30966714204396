import { Button, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import CheckAnmeldung from "../componets/Anmeldung/CheckAnmeldung";
import SpieleWochenendeTexte from "../componets/Spielewochenende/SpieleWochenendeTexte";
import { AktionsChild } from "../componets/Zeltlager/AktionsChild";
import { useAuth } from "../context/AuthContext";
import { useSpielewochenendeBooking } from "../context/SpielewochenendeBookingCOntext";
import { useSpielewochenende } from "../context/SpielewochenendeContext";
import { auth } from "../configuration/firebase";
import DirectionsIcon from '@mui/icons-material/Directions';
import { getSpielewochenendeBookingDTO, getSpielewochenendeBookingFormState } from "../mapper/SpielewochenendeBookingMaper";
import { AktionChildDTO, SpielewochenendeBookingDTO, SpielewochenendeBookingFormState, SpielewochenendeDTO } from "../models";
import { getSpielewochenendeBookingForUser } from "../services/SpielewochenendeBookingService";
import TextSplitter from "../componets/TextSplitter";
import { SpielewochenendeAnmeldung } from "../componets/Spielewochenende/SpielewochenendeAnmeldung";
import { validateBookingSpielewochenende } from "../validator/SpielewochenendeBookingValidator";
import { SuccessNotification } from "../configuration/notifications";
import { UpdateSpielewochenendeBooking } from "../componets/Spielewochenende/UpdateSpielewochenendeBooking";

export const Spielewochenende = () => {
    const auth = useAuth();

    const [value, setValue] = React.useState<Dayjs | null>(dayjs())
    const [checkBoxRegistryChecked, setCheckBoxRegistryChecked] = useState<boolean>(false)
    const [checkBoxCarCapacityChecked, setCheckBoxCarCapacityChecked] = useState<boolean>(false)
    const [spielewochenende, setSpielewochenende] = useState<SpielewochenendeDTO>({})
    const [newBooking, setNewBooking] = useState<SpielewochenendeBookingFormState>({ email: auth.currentUser?.email } as SpielewochenendeBookingFormState)
    const [booking, setBooking] = useState<SpielewochenendeBookingFormState>({})
    const [bookingActive, setBookingActive] = useState(false)
    const [children, setChildren] = useState<AktionChildDTO[]>([])

    const spielewochenendeContext = useSpielewochenende();
    const spielewochenendeBookingContext = useSpielewochenendeBooking();
    const year = new Date().getFullYear();

    useEffect(() => {
        if (auth.currentUser !== null && auth.currentUser !== undefined) {
            spielewochenendeContext.getSpielewochenende().then(result => {
                if (result !== undefined && result !== null) {
                    setSpielewochenende(result)
                    setBookingActive(result.active!)
                }
            }).finally(() => {
                spielewochenendeBookingContext.setLoading(false)
            })
            spielewochenendeBookingContext.getSpielewochenendeBookingForUser().then(result => {
                let formState = getSpielewochenendeBookingFormState(result)
                if (formState.id !== undefined) {
                    setBooking(formState)
                    setCheckBoxCarCapacityChecked(formState.parent?.carCapacity! > 0)
                    setChildren(result.children!)
                }
            }).finally(() => {
                spielewochenendeBookingContext.setLoading(false)
            })
        }
    }, [])

    const addChild = () => {
        setChildren([...children, {} as AktionChildDTO])
    }

    const updateBooking = () => {
        if (validateBookingSpielewochenende(booking, children)) {
            let spielewochenendeBooking = getSpielewochenendeBookingDTO(booking, children)
            spielewochenendeBookingContext.updateSpielewochenendeBooking(spielewochenendeBooking).then(result => {
                setBooking(result)
                SuccessNotification("Die Spielewochenendeanmeldung wurde erfolgreich aktualisiert!")
            }).finally(() => {
                spielewochenendeBookingContext.setLoading(false)
            })
        }
    }

    const deleteBooking = () => {
        spielewochenendeBookingContext.deleteSpielewochenendeBooking(booking.id!).then(result => {
            setBooking({})
            setChildren([])
            setCheckBoxCarCapacityChecked(false)
            setCheckBoxRegistryChecked(false)
            SuccessNotification("Die Spielewochenendeanmeldung wurde erfolgreich storniert")
        }).finally(() => {
            spielewochenendeBookingContext.setLoading(false)
        })
    }

    const registerChildren = () => {
        if (validateBookingSpielewochenende(newBooking, children)) {
            let spielewochenendeBooking = getSpielewochenendeBookingDTO(newBooking, children)
            spielewochenendeBookingContext.createSpielewochenendeBooking(spielewochenendeBooking).then(result => {
                setNewBooking({})
                setChildren(result.children!)
                let formState = getSpielewochenendeBookingFormState(result)
                setBooking(formState)
                SuccessNotification("Die Spielewochenendeanmeldung wurde erfolgreich gespeichert!")
            }).finally(() => {
                spielewochenendeBookingContext.setLoading(false)
            })
        }
        
    }

    const reload = () => {
        if (auth.currentUser !== null && auth.currentUser !== undefined) {
            spielewochenendeContext.getSpielewochenende().then(result => {
                if (result !== undefined && result !== null) {
                    setSpielewochenende(result)
                    setBookingActive(result.active!)
                }
            }).finally(() => {
                spielewochenendeContext.setLoading(false);
            });
            spielewochenendeBookingContext.getSpielewochenendeBookingForUser().then(result => {
                let formState = getSpielewochenendeBookingFormState(result)
                if (formState.id !== undefined) {
                    setBooking(formState)
                    setCheckBoxCarCapacityChecked(formState.parent?.carCapacity! > 0)
                    setChildren(result.children!)
                }
            }).finally(() => {
                spielewochenendeBookingContext.setLoading(false);
            })
        }
    }

    return (
        <div>
            <Container sx={{ paddingBottom: '4%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <br />
                <br />
                <br />
                <SpieleWochenendeTexte />
                <TextSplitter />
                <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {
                        auth.currentUser === undefined || auth.currentUser === null ? (
                            <CheckAnmeldung />
                        ) : (
                            spielewochenendeBookingContext.loading === true ? (<CircularProgress></CircularProgress>) : (
                                spielewochenende != null ? (
                                    spielewochenende.active ? (
                                        <Container>
                                        
                                                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <h1>Informationen über das Spielewochenende</h1>
                                                    </Grid>
                                                    <br />
                                                    <Grid>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="center">Wann</TableCell>
                                                                        <TableCell align="center">Wo?</TableCell>
                                                                        <TableCell align="center">Preis (in Euro)</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>

                                                                    <TableRow>
                                                                        <TableCell align="center">
                                                                            {new Date(spielewochenende.startDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })} - {new Date(spielewochenende.endDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}
                                                                        </TableCell>
                                                                        <TableCell align="center"> {spielewochenende.location}
                                                                            <a href={spielewochenende.locationLink} target="_blank" style={{ color: 'green' }} rel="noreferrer">
                                                                                <DirectionsIcon></DirectionsIcon>
                                                                            </a>
                                                                        </TableCell>
                                                                        <TableCell align="center">{spielewochenende.price}</TableCell>
                                                                    </TableRow>

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>


                                                        <br /><br />
                                                        <p>Bei der Zahlung geben wir die Möglichekeit einer Preisminderung:
                                                            Unser regulärer Betrag für das Spielewochenende pro Kind beträgt {spielewochenende.price} Euro.
                                                        </p>
                                                        <p>Bei einer Anmeldung mit mehreren Geschwistern gibt es eine Minderung um 5 Euro.
                                                            Somit kostet das 1. Kind {spielewochenende.price} Euro und jedes weitere Kind nur noch {spielewochenende.price! - 5} Euro.
                                                        </p>
                                                        <TextSplitter />
                                                    </Grid>
                                            
                                        {
                                            booking.id !== undefined ? (
                                                <UpdateSpielewochenendeBooking
                                                    addChild={addChild}
                                                    disableButtons={!spielewochenende.active}
                                                    checkBoxCarCapacityChecked={checkBoxCarCapacityChecked}
                                                    setCheckBoxCarCapacityChecked={setCheckBoxCarCapacityChecked}
                                                    children={children}
                                                    setChildren={setChildren}
                                                    setSpielewochenendeBooking={setBooking}
                                                    spielewochenendeBooking={booking}
                                                    updateBooking={updateBooking}
                                                    deleteBooking={deleteBooking}
                                                ></UpdateSpielewochenendeBooking>
                                            ) : (
                                                <SpielewochenendeAnmeldung
                                                    addChild={addChild}
                                                    checkBoxCarCapacityChecked={checkBoxCarCapacityChecked}
                                                    checkBoxRegistryChecked={checkBoxRegistryChecked}
                                                    children={children}
                                                    newBooking={newBooking}
                                                    registerChildren={registerChildren}
                                                    setCheckBoxCarCapacityChecked={setCheckBoxCarCapacityChecked}
                                                    setCheckBoxRegistryChecked={setCheckBoxRegistryChecked}
                                                    setChildren={setChildren}
                                                    setNewBooking={setNewBooking}

                                                ></SpielewochenendeAnmeldung>
                                            )
                                        }
                                        <br /><br />
                                    </Container>
                                    ): (    
                                        booking.id !== undefined ? (
                                            <UpdateSpielewochenendeBooking
                                                addChild={addChild}
                                                disableButtons={false}
                                                checkBoxCarCapacityChecked={checkBoxCarCapacityChecked}
                                                setCheckBoxCarCapacityChecked={setCheckBoxCarCapacityChecked}
                                                children={children}
                                                setChildren={setChildren}
                                                setSpielewochenendeBooking={setBooking}
                                                spielewochenendeBooking={booking}
                                                updateBooking={updateBooking}
                                                deleteBooking={deleteBooking}
                                            ></UpdateSpielewochenendeBooking>
                                        ) : (
                                            <>
                                                <h2>Die Anmeldung ist aktuell nicht aktiv</h2>
                                                <Button variant="contained" onClick={() => reload()} style={{width: '200px'}}>Neu laden</Button>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                            </>
                                        )
                                    )
                                ) : (
                                    <>
                                        <h2>Die Anmeldung ist aktuell nicht aktiv</h2>
                                        <Button variant="contained" onClick={() => reload()} style={{width: '200px'}}>Neu laden</Button>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                    </>
                                )
                            )
                        )
                    }
                </Container>
            </Container>
        </div>
    )
}
