
type Props = {}

export default function TextSplitter({ }: Props) {
    return (
        <div>
            <br />
            <hr />
            <br />
        </div>
    )
}