import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { Meeting, NikolausaktionDTO } from "../models";
import * as service from "../services/NikolausaktionService"

interface NikolausaktionContextInterface {
    loading: boolean,
    getNikolausaktion: () => Promise<NikolausaktionDTO>
    updateNikolausaktion: (nikolausAktion: NikolausaktionDTO) => Promise<NikolausaktionDTO>
    createNikolausaktion: (nikolausAktion: NikolausaktionDTO) => Promise<NikolausaktionDTO>
    deleteNikolausaktion: () => Promise<void>
    getAvailableMeetings: () => Promise<Meeting[]>
    setLoading: Dispatch<SetStateAction<boolean>>
  }
  
  
  const Context = React.createContext<NikolausaktionContextInterface>({
    loading: false,
    getNikolausaktion: () => new Promise<NikolausaktionDTO>(() => {}),
    updateNikolausaktion: (nikolausAktion: NikolausaktionDTO) => new Promise<NikolausaktionDTO>(() => {}),
    createNikolausaktion: (nikolausAktion: NikolausaktionDTO) => new Promise<NikolausaktionDTO>(() => {}),
    deleteNikolausaktion: () => new Promise<void>(() => {}),
    getAvailableMeetings: () => new Promise<Meeting[]>(() => {}),
    setLoading: () =>{}
  });
  
  const NikolausaktionContext: FC<{ children: ReactNode }> = (props) => {
  
    const [loading, setLoading] = useState(false)
  
    const getNikolausaktion = async (): Promise<NikolausaktionDTO> => {
        setLoading(true)
        const response = await service.getNikolausaktion();
        return response as NikolausaktionDTO
    }

    const createNikolausaktion = async (nikolausAktion: NikolausaktionDTO): Promise<NikolausaktionDTO> => {
        setLoading(true)
        const response = await service.createNikolausaktion(nikolausAktion);
        return response as NikolausaktionDTO
    }

    const updateNikolausaktion = async (nikolausAktion: NikolausaktionDTO): Promise<NikolausaktionDTO> => {
        setLoading(true)
        const response = await service.updateNikolausaktion(nikolausAktion);
        return response as NikolausaktionDTO
    }

    const deleteNikolausaktion = async (): Promise<void> => {
        setLoading(true)
        const response = await service.deleteNikolausaktion();
        return response;
    }

    const getAvailableMeetings = async (): Promise<Meeting[]> => {
      setLoading(true)
      const response = await service.getAvailableMeetings();
      return response;
  }
  
    return (
      <Context.Provider
        value={{
          loading,
          setLoading,
          getNikolausaktion,
          updateNikolausaktion,
          createNikolausaktion,
          deleteNikolausaktion,
          getAvailableMeetings
        }}>
        {props.children}
      </Context.Provider>
    );
  }
  export default NikolausaktionContext;
  export const useNikolausaktion = () => React.useContext(Context)
  