import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useEffect } from "react";
import { SuccessNotification } from "../../configuration/notifications";
import { useSpielewochenendeBooking } from "../../context/SpielewochenendeBookingCOntext";
import { useSpielewochenende } from "../../context/SpielewochenendeContext";
import { SpielewochenendeBookingDTO, SpielewochenendeDTO } from "../../models";
import { SpielewochenendeBookings } from "../Spielewochenende/SpielewochenendeBookings";
import TextSplitter from "../TextSplitter";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SpielewochenendeAdmin: FC = () => {
    const [valueStartDate, setValueStartDate] = React.useState<Dayjs>(dayjs(Date.now()));
    const [valueEndDate, setValueEndDate] = React.useState<Dayjs>(dayjs(Date.now()));

    const [active, setActive] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    const spielewochenendeContext = useSpielewochenende();
    const spielewochenendeBookingContext = useSpielewochenendeBooking();

    const [spielewochenende, setSpielewochenende] = React.useState<SpielewochenendeDTO>({})
    const [newSpielewochenende, setNewSpielewochenende] = React.useState<SpielewochenendeDTO>({ startDate: new Date(), endDate: new Date() })

    useEffect(() => {
        spielewochenendeContext.getSpielewochenende().then(result => {
            if (result !== undefined && result !== null) {
                setSpielewochenende(result)
                setActive(result.active!)
            }
        }).finally(() => {
            spielewochenendeContext.setLoading(false)
        })
    }, [])

    const handleClickOpenDelete = () => {
        setOpen(true);
    };
    const handleCloseDelete = () => {
        setOpen(false);
    };
    const handleChangeStartDate = (newValue: Dayjs) => {
        setValueStartDate(newValue);
    };
    const handleChangeEndDate = (newValue: Dayjs) => {
        setValueEndDate(newValue);
    };

    const handleDelete = (spielewochenendeDataId: string) => {
        spielewochenendeContext.deleteSpielewochenende(spielewochenendeDataId).then(result => {
            setSpielewochenende({})
            setActive(false)
            handleCloseDelete()
            SuccessNotification("Das Spielewochenende wurde erfolgreich gelöscht!")
        }).finally(() => {
            spielewochenendeContext.setLoading(false)
        })
    }

    const addSpielewochenende = () => {
        newSpielewochenende.active = active
        spielewochenendeContext.createSpielewochenende(newSpielewochenende).then(result => {
            setSpielewochenende(result)
            setActive(result.active!)
            setNewSpielewochenende({})
            SuccessNotification("Das Spielewochenende wurde erfolgreich hinzugefügt!")
        }).finally(() => {
            spielewochenendeContext.setLoading(false)
        })
    }

    const updateSpielewochenende = () => {
        spielewochenende.active = active
        spielewochenendeContext.updateSpielewochenende(spielewochenende).then(result => {
            setSpielewochenende(result)
            setActive(result.active!)
            SuccessNotification("Das Spielewochenende wurde erfolgreich aktualisert")
        }).finally(() => {
            spielewochenendeContext.setLoading(false)
        })
    }


    return (
        <>
            {
                spielewochenendeContext.loading === true ? (
                    <CircularProgress></CircularProgress>
                ) : (
                    <Container>
                        <Container sx={{ display: 'inline-flex', alignItems: 'center' }}>
                            <Switch type={"checkbox"} checked={active} onChange={(value) => setActive(value.target.checked)} />
                            <h3>Spielewochenende aktiv</h3>
                            <br /><br />
                            <Button style={{ marginLeft: '6%' }} variant="contained" onClick={() => updateSpielewochenende()}>Aktualisieren</Button>
                        </Container>

                        <Container sx={{ marginBottom: '2%' }}>
                            {
                                spielewochenende.id !== null && spielewochenende.id !== undefined ? (
                                    <>
                                        <TextSplitter />
                                        <h3>Übersicht Daten</h3>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Wann?</TableCell>
                                                        <TableCell align="center">Wo?</TableCell>
                                                        <TableCell align="center">Preis (in Euro)</TableCell>
                                                        <TableCell align='center'></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    <TableRow>
                                                        <TableCell align="center">
                                                            {new Date(spielewochenende.startDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })} - {new Date(spielewochenende.endDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}
                                                        </TableCell>
                                                        <TableCell align="center">{spielewochenende.location}</TableCell>
                                                        <TableCell align="center">{spielewochenende.price}</TableCell>
                                                        <TableCell align="center">
                                                            <Button
                                                                onClick={handleClickOpenDelete}
                                                                variant="contained"
                                                            >
                                                                Löschen
                                                            </Button>

                                                            <Dialog
                                                                open={open}
                                                                TransitionComponent={Transition}
                                                                keepMounted
                                                                onClose={handleCloseDelete}
                                                                aria-describedby="deleteZeltlagerData"
                                                            >
                                                                <DialogTitle>{"Zeltlager dauerhaft löschen?"}</DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="deleteZeltlagerData">
                                                                        Zeltlager wirklich löschen?
                                                                        Denk nochmal nach
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleCloseDelete}>Nein</Button>
                                                                    <Button onClick={() => handleDelete(spielewochenende.id!)}>Ja</Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </TableCell>
                                                    </TableRow>


                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                ) : (
                                    <>
                                        <TextSplitter />
                                        <h2>Termin hinzufüge</h2>
                                        <Grid xs={12} sm={4} marginBottom={'3%'}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    label="Start-Tag"
                                                    inputFormat="DD.MM.YYYY"
                                                    minDate={dayjs()}
                                                    value={valueStartDate}
                                                    onChange={(newValue) => {
                                                        setNewSpielewochenende({ ...newSpielewochenende, startDate: newValue?.toDate() })
                                                        handleChangeStartDate(newValue as Dayjs)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DesktopDatePicker
                                                    label="End-Tag"
                                                    inputFormat="DD.MM.YYYY"
                                                    value={valueEndDate}
                                                    onChange={(newValue) => {
                                                        setNewSpielewochenende({ ...newSpielewochenende, endDate: newValue?.toDate() })
                                                        handleChangeEndDate(newValue as Dayjs)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>

                                        </Grid>
                                        <Grid item xs={12} sm={11} marginBottom={'3%'}>
                                            <TextField
                                                required
                                                label="Standort"
                                                id="standort"
                                                fullWidth
                                                onChange={(newText) => {
                                                    setNewSpielewochenende({ ...newSpielewochenende, location: newText.target.value })
                                                }}
                                            ></TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={11} marginBottom={'3%'}>
                                            <TextField
                                                required
                                                label="Standort Maps Link"
                                                id="standort-maps-link"
                                                fullWidth
                                                onChange={(newText) => {
                                                    setNewSpielewochenende({ ...newSpielewochenende, locationLink: newText.target.value })
                                                }}
                                            ></TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={3} marginBottom={'3%'}>
                                            <TextField
                                                required
                                                label="Preis in Euro"
                                                name="quantitiy"
                                                type="number"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                id="preis"
                                                sx={{marginRight: '1em'}}
                                                onChange={(newText) => {
                                                    setNewSpielewochenende({ ...newSpielewochenende, price: Number.parseInt(newText.target.value) })
                                                }}
                                            ></TextField>
                                            <TextField
                                                required
                                                label="Limit Personen"
                                                name="Limit"
                                                type="number"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                                id="preis"
                                                onChange={(newText) => {
                                                    setNewSpielewochenende({ ...newSpielewochenende, limit: Number.parseInt(newText.target.value) })
                                                }}
                                            ></TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Button variant="contained" onClick={() => addSpielewochenende()}>
                                                Hinzufügen
                                            </Button>
                                        </Grid>
                                    </>

                                )
                            }

                        </Container>
                        <SpielewochenendeBookings></SpielewochenendeBookings>
                    </Container>
                )
            }
        </>
    )
}
