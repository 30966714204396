import { Container } from '@mui/material'
import React from 'react'
import { ReadMore } from '../ReadMore'

type Props = {}

export default function HomeTexte({ }: Props) {
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <h1>Über Uns</h1>
            <br /><br />
            <h4>Wer wir sind?</h4>
            <ReadMore>
                Die Pfarrjugend Neusäß der Pfarrgemeinde Neusäß/Aystetten setzt sich aus Kindern und Jugendlichen und deren Jugendgruppenleitern zusammen. Bereits seit mehreren
                Generationen finden sich diese Jugendgruppenleiter jeden Monat mehrmals mit den Kindern der zugehörigen Pfarreien zu Gruppenstunden zusammen, bei denen gemeinsame
                Aktivitäten unternommen werden. Nahezu alle unsere Leiter, waren früher selbst Teil dieser Jugendgruppen. Mittlerweile sind sie zwar aus ihrer eigenen Gruppe
                herausgewachsen, bleiben aber selbstverständlich Teil der Pfarrjugend, indem sie nun in die Fußstapfen ihrer Vorgänger treten. Sie übernehmen nun selbst die Leitung
                neuer Jugendgruppen und engagieren sich bei den restlichen Aktivitäten, die die Pfarrjugend über das gesamte Jahr hinweg veranstaltet. Allerdings sind auch einige
                Jugendleiter in unserem Team, die als gute Freunde dazu stießen und mittlerweile ein ebenso wichtiger Bestandteil unserer Pfarrjugend geworden sind. Im Großen und Ganzen
                ist also jeder in unserem Leiterteam willkommen, der Lust hat sich einzubringen und mitanzupacken. Die Mitglieder des Leiterteams sind alle zwischen 16 und 23 Jahre alt.
                Neben Schule, Ausbildung, Studium oder Beruf finden sie sich ehrenamtlich einmal im Monat im Pfarrheim in Aystetten oder Täfertingen zusammen, um die Aktivitäten der
                Pfarrjugend zu planen.
            </ReadMore>
            <br /><br />
            <h4>Was wir machen?</h4>
            <ReadMore>
                Neben den Gruppenstunden, die mehrmals im Monat in den jeweiligen Pfarrheimen stattfinden, organisieren die Leiter schon seit mehreren Generationen das ganze Jahr über
                weitere Aktionen. Bald nach Beginn des neuen Schuljahres, steht jedes Jahr das Spielewochenende an: Dabei ist von Brett- und Kartenspielen über Tischtennis-Matches bis hin
                zu Gruppenspielen, bei denen alle gemeinsam mitmachen können, alles geboten. Dafür steht den Kindern und Leitern ein ganzes Wochenende zur Verfügung, das zusammen in einer
                Unterkunft verbracht wird.
                Selbstverständlich begleitet die Pfarrjugend auch die Weihnachtszeit: Zum einen schicken wir bei unserer Nikolausaktion unsere ganzen Nikoläuse, Knecht Ruprechte und Engel durch die
                Gemeinde Aystetten und die Neusäßer Ortsteile, um die Familien am Nikolaustag selbst, sowie zwei Tage zuvor zu begleiten. Zum anderen veranstalten wir jedes Jahr eine Waldweihnacht,
                bei der alle Kinder, deren Eltern, Großeltern und Geschwister herzlich eingeladen sind, in mehreren Stationen die Weihnachtsgeschichte zu hören, Weihnachtslieder zu singen und zu
                einer Tasse Punsch zusammenzukommen. Im neuen Jahr bietet dann der Pfarrfasching die Gelegenheit, sich in ein lustiges Kostüm zu schmeißen und gemeinsam einen netten Abend zu
                verbringen. Die Pfarrjugend versucht sich jedes Jahr ein amüsantes Programm zu überlegen, bei dem kreative Tänze und lustige Einlagen selbstverständlich nicht fehlen dürfen.
                Doch der Höhepunkt der Pfarrjugend ist für alle das Zeltlager, das jährlich in der ersten Sommerferienwoche veranstaltet wird. Jahr für Jahr verbringen wir dort eine unvergessliche
                Zeit miteinander, was uns immer noch ein Stück mehr zusammenschweißt. Selbstverständlich wird alles davon mit vielen Fotos festgehalten, welche dann am Diaabend im November gemeinsam
                mit den Eltern angesehen werden können, um die schöne Zeit noch einmal Revue passieren zu lassen. Selbstverständlich ist zu diesem Zeitpunkt die Planung für das kommende Jahr schon
                wieder im vollen Gange, um allen so viel Spaß und Freude ermöglichen zu können, wie es nur geht.

            </ReadMore>
        </Container>)
}