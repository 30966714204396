import { Container } from '@mui/material'
import { ReadMore } from "../ReadMore";
import TextSplitter from '../TextSplitter';

type Props = {}

export default function Zeltlagertexte({ }: Props) {
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <h1>Zeltlager</h1>
            <br /><br />
            <h4>Zeltlager allgemein</h4>
            <ReadMore>
                Es gibt nicht viele Gründe für Kinder an ihrem ersten Sommerferientag früh aufzustehen, doch für eine Woche
                Spaß und Action, eine Woche voller neuer Bekanntschaften und eine Woche ohne Eltern lohnt es sich!
                Das Zeltlager der Pfarrgemeinschaft Neusäß gründet auf jahrzehnterlanger Tradition. Jedes Jahr am ersten
                Feriensamstag starten circa 50 Kinder und Jugendliche verschiedenen Alters zusammen mit einem ungefähr
                15-köpfigen Leiterteam in ein neues Abenteuer. Gemeinsam machen sich dann alle auf den Weg zum Zeltplatz.
                Dort angekommen wird erst mit dem Aufschlagen der eigenen Zelte begonnen und im Anschluss beim Aufbau des
                Aufenthalts-, Spiele-, Wekstatt- und Kochzelts zusammengeholfen. Danach geht es an das Juwel des Zeltlagers:
                Eine Fahne und ein geeigneter Mast muss her! Passend zum jährlich wechselnden Motto wird die Flagge bemalt und
                ein Baum für den Fahnenmast gefällt. Mit dem Hissen der Flagge kann das Zeltlager dann so richtig eingeläutet
                werden. Sie ist nicht nur unser Zeltlagerwappen, sondern auch von anderen Pfarreien heiß begehrt. Um sie vor
                deren Raubzügen zu schützen, wird sie jede Nacht durch stündlich wechselnde Patrouillen bewacht. Trauen sich
                Eindringlinge dann doch auf unseren Zeltplatz, wird jeder mit einem lauten Glockenschlag geweckt und die
                Flagge gemeinsam beschützt.
                Von solch einer spannenden Nacht kann sich am nächsten Tag am Bach erholt werden. Doch meistens dauert es nicht
                lange und die Entspannung wird durch eine Wasserschlacht unterbrochen. Doch auch tagsüber hält das Zeltlager
                noch viele weitere gemeinschaftliche Aktivitäten bereit. Die Jugendleiter übernehmen die bereits ein Jahr im
                voraus geplante Organisation des Lagers und sorgen für ein abwechslungsreiches Programm: Von Naturralleys,
                unserem Geisterpfad und einer Schnitzeljagd über Gemeinschaftsspiele, Fußball- und Volleyballturniere bis
                hin zu Bastelaktionen und gemeinsamen Abenden am Lagerfeuer ist in unserem Zeltlager alles geboten. Der
                bunte Abend krönt die gemeinsame Woche und hält zum Abschluss des Zeltlagers jedes Jahr neue
                Überraschungen bereit. Wir freuen uns auch im nächsten Sommer wieder auf ein weiteres Abenteuer,
                spannende Gemeinschaftserlebnisse und ein harmonisches Miteinander.
            </ReadMore>
            <br /><br />
            <h4>Zeltlager 2023</h4>
            <ReadMore>
            Am 29.07.2023 trafen sich unsere Kids mit einem Teil der Leiter an der Kirche in Sankt Thomas Morus um gesammelt in Fahrgemeinschaften zum Zeltplatz in Krumbach zu 
            fahren, um eine gemeinsame Woche voller Spannung, Spaß und großartigen Erlebnissen zu verbringen.
            Mit einer gemeinsamen Runde lernten wir uns alle kennen, bevor die Zelte, ein neuer Glockenturm und der Fahnenmast mit dem Motto „Märchen“ aufgestellt wurden, 
            abends gab es zum Stärken Kässpatzen. Nachts besuchten uns auch die ersten Überfäller, die unsere Fahne zum Glück nicht zu fassen bekamen. Am Sonntag ging wortwörtlich 
            das Chaos der Nacht am Tag mit unserem Legendären Chaos-Spiel weiter. Nach ein paar Runden Kettenfangus leiteten wir den Abend mit einem gemütlichen Singen am Feuer ein.
             Montags erwachten die müden Gemüter mit einer kleinen Wasserschlacht nach dem Frühstück bei Sonnenschein, sodass wir am Nachmittag wieder alle Trocken 
             unseren gemeinsamen Zeltlagergottesdienst mit Kaplan Riedel feiern konnten. In den Zwischenzeiten waren wie jeden Tag Karten, Volleyball, Fußball oder
              Gemeinsames Basteln im Bundeswehrzelt von Armbändern oder Ketten aus Perlen oder Luumbändern Tagesprogramm. Der Abend leitete sich dann mit dem ungeahnten 
              Geisterpfad durch eine kleine Show und Geschichte ein, trotzdem waren unsere Kinder mutig und sind durch den Geisterpfad mit gruseligen Stationen durchgelaufen.
               Am kommenden Tag wurde bei Sonnenschein die große Naturrally ganz nach dem Motto Märchen in kleinen Gruppen gemeistert, welche nach getaner Arbeit am Bach endete, 
               erfrischt und abgekühlt verbrachten die Kinder den restlichen Tag ganz nach ihren eigenen Vorstellungen. Am Donnerstag bastelten alle nach dem Frühstück schon 
               fleißig für den Bunden Abend Hexenbesen, bevor es am Nachmittag wie letztes Jahr zu unserem Bauern der Wiese ging, um eine interessante Führung über seinen 
               Hof zu bekommen, als alle wieder am Zeltplatz ankamen, gab es dann für jedes Kind ein Pfarrjugend T-Shirt, mit dem im Anschluss unser Gruppenfoto gemacht wurde. 
               Während unsere Leiter schon mal die Kohle für das Grillen am Abend vorbereiten, führten wir unsere Tradition des „Gras Kämmens“ fort…
            Nach dem leckeren Essen begann dann schon der Bunte Abend ganz nach dem Motto „Deisenhausen sucht die super Hexe“ mit spannenden Aufgaben versuchte jedes Team 
            sein Bestes! Anschließend kürten wir noch die Sieger der Naturrally, des Chaos-Spiels und des Bunten Abends. Der restliche Abend wurde dann gemeinsam mit 
            Musik, Singen und Tanzen verbracht.
            Nach dem Frühstück am Freitag bauten alle Kinder ihre Zelte ab und machten den Zeltplatz gründlichst sauber, bevor alle Eltern zum Abholen kamen. Samstags kamen wir zu einem gemeinsamen Weißwurstfrühstück zusammen, um anschließend den Hänger auszuräumen und die restlichen Spuren des Zeltlagers zu beseitigen. Die Spuren unserer Erinnerungen aber bleiben und werden mit dem gemeinsamen Abschlussgrillen am letzten Lagerfeuer wie man sich es wünscht abgerundet. 
            Wir freuen uns jetzt schon auf das kommende Zeltlager 2024!
            </ReadMore>
            <TextSplitter />
        </Container>
    )
}