import { toast } from "react-toastify";
import { AktionChildDTO, SpielewochenendeBookingFormState } from "../models";

export function validateBookingSpielewochenende(spielewochenendeBooking: SpielewochenendeBookingFormState, children: AktionChildDTO[]): boolean {
    if(
        spielewochenendeBooking.address?.city !== null && spielewochenendeBooking.address?.city !== undefined &&
        spielewochenendeBooking.address.houseNumber !== null && spielewochenendeBooking.address?.houseNumber !== undefined &&
        spielewochenendeBooking.address?.plz !== null && spielewochenendeBooking.address?.plz !== undefined &&
        spielewochenendeBooking.address?.street !== null && spielewochenendeBooking.address?.street !== undefined &&
        spielewochenendeBooking.parent?.firstName !== null && spielewochenendeBooking.parent?.firstName !== undefined &&
        spielewochenendeBooking.parent?.lastName !== null && spielewochenendeBooking.parent?.lastName !== undefined &&
        spielewochenendeBooking.parent?.phoneNumber !== null && spielewochenendeBooking.parent?.phoneNumber !== undefined
    )
    {
        for(let index = 0; index < children.length; index++) {
            if(
                children[index].firstName !== null && children[index].firstName !== undefined &&
                    children[index].lastName !== null && children[index].lastName !== undefined &&
                    children[index].birthday !== null && children[index].birthday !== undefined &&
                    children[index].gender !== null && children[index].gender !== undefined
            ) {

            }else {
                toast.warn("Bitte alle mit * gekennzeichneten Felder ausfüllen")
                return false;
            }
        }
        return true
    }
    toast.warn("Bitte alle mit * gekennzeichneten Felder ausfüllen")
    return false
}