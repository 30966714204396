import { Container } from "@mui/material";
import { FC } from "react";

export const GruppenStunden: FC = () => {
    return (
        <div>
            <br /><br /><br />
            <Container sx={{ paddingBottom: '4%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                <h3>Gruppenstunden in Neusäß</h3>
                <p>
                    In unseren Gemeinden der Pfarrjugend Neusäß finden jeden Monat Gruppenstunden statt.
                    In diesen sind Kinder ab einem Alter von 9 Jahren herzlich willkommen, um mit unseren Leitern verschiedenste Spiele zu spielen oder zu backen.
                </p>
                <p>
                    In unserer Pfarrei sind die Gruppenstunden an folgenden Terminen im Monat:
                </p>
                <p>Thomas Morus (Gregor-Mendel-Straße 1, 86356 Neusäß):</p>
                <p style={{ marginLeft: '4%'}}>- Immer Freitags</p>
                <p>Täfertingen (Pestalozzistraße 10, unter dem Kindergarten):</p>
                <p style={{ marginLeft: '4%'}}>- in geraden Wochen: für Kinder in der 4. Klasse am Freitag um 17:00 Uhr</p>
            </Container>
            <br /><br /><br />
        </div>
    )
}