import axios from "axios";
import { read } from "fs";
import axiosInstance from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { SpielewochenendeBookingDTO } from "../models";

export const createSpielewochenendeBooking = async (spielewochenendeBooking: SpielewochenendeBookingDTO) => {
    return await axiosInstance.post('SpielewochenendeBooking/CreateSpielewochenendeBooking', spielewochenendeBooking)
        .then(response => {
            return response.data as SpielewochenendeBookingDTO
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
              } else {
                ErrorNotification(undefined, reason.message)
              }
            throw reason;
        })
}

export const updateSpielewochenendeBooking = async (spielewochenendeBooking: SpielewochenendeBookingDTO) => {
    return await axiosInstance.put('SpielewochenendeBooking/UpdateSpielewochenendeBooking', spielewochenendeBooking)
        .then(response => {
            return response.data as SpielewochenendeBookingDTO
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
              } else {
                ErrorNotification(undefined, reason.message)
              }
            throw reason;
        })
}

export const getSpielewochenendeBookingForUser = async () => {
    return await axiosInstance.get('SpielewochenendeBooking/GetSpielewochenendeBookingForUser')
        .then(response => {
            return response.data as SpielewochenendeBookingDTO
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const deleteSpielewochenendeBookingAsAdmin = async (id: string, childId: string, emailText: string) => {
    var formData = new FormData();
    formData.append("emailText", emailText)
    await axiosInstance.post('SpielewochenendeBooking/DeleteSpielewochenendeBookingAsAdmin/' + id + "/" + childId, formData)
        .catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
              } else {
                ErrorNotification(undefined, reason.message)
              }
            throw reason;
        })
}

export const deleteSpielewochenendeBooking = async (id: string) => {
    await axiosInstance.delete('SpielewochenendeBooking/DeleteSpielewochenendeBooking/' + id)
        .catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
              } else {
                ErrorNotification(undefined, reason.message)
              }
            throw reason;
        })
}

export const getAllSpielewochenendeBookings = async () => {
    return await axiosInstance.get('SpielewochenendeBooking/GetAllSpielewochenendeBookings')
        .then(response => {
            return response.data as SpielewochenendeBookingDTO[]
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
              } else {
                ErrorNotification(undefined, reason.message)
              }
            throw reason;
        })
}