import axios, { AxiosInstance } from "axios"
import { onAuthStateChanged, User, UserCredential, updateEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword, sendEmailVerification, updateProfile, deleteUser } from "firebase/auth"
import React, { useState, useEffect, ReactNode, FC } from "react"
import { auth } from "../configuration/firebase"

interface AuthContextInterface {
  loading: boolean,
  currentUser: User | null | undefined,
  signup: (email: string, passowrd: string) => Promise<UserCredential>,
  login: (email: string, passowrd: string) => Promise<UserCredential>,
  logout: () => Promise<void>,
  resetPassword: (email: string) => Promise<void>,
  updateEmailAdress: (email: string) => Promise<void>,
  updatePasswordForUser: (password: string) => Promise<void>
  sendVerificationEmail: (user: User) => Promise<void>,
  updateProfileOfUser: (user: User, name: string) => Promise<void>,
  deleteUserProfile: () => Promise<void>
}


const Context = React.createContext<AuthContextInterface>({
  loading: false,
  currentUser: null,
  signup: () => new Promise(() => { }),
  login: () => new Promise(() => { }),
  logout: () => new Promise(() => { }),
  resetPassword: () => new Promise(() => { }),
  updateEmailAdress: () => new Promise(() => { }),
  updatePasswordForUser: () => new Promise(() => { }),
  sendVerificationEmail: (user: User) => new Promise(() => { }),
  updateProfileOfUser: (user: User, name: string) => new Promise(() => {}),
  deleteUserProfile: () => new Promise(() => {})
});

const AuthContext: FC<{ children: ReactNode }> = (props) => {

  const [currentUser, setCurrentUser] = useState<User | null>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // https://firebase.google.com/docs/auth/web/manage-users
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email)
  }

  function updateEmailAdress(email: string) {
    if (auth.currentUser !== null) {
      return updateEmail(auth.currentUser, email)
    }
    return Promise.reject("Fehler")
  }

  function sendVerificationEmail(user: User) {
      return sendEmailVerification(user)
  }

  function updatePasswordForUser(password: string) {
    if (auth.currentUser !== null) {
      return updatePassword(auth.currentUser, password)
    }
    return Promise.reject("Fehler")
  }


  function updateProfileOfUser(user: User, name: string) {
      return updateProfile(user, {displayName: name})
  }

  function deleteUserProfile () {
    if(currentUser!== null && currentUser !== undefined){
      return deleteUser(currentUser)
    }
    return Promise.reject("Fehler")
  }


  return (
    <Context.Provider
      value={{
        loading,
        signup,
        login,
        logout,
        resetPassword,
        updateEmailAdress,
        updatePasswordForUser,
        currentUser,
        updateProfileOfUser,
        sendVerificationEmail,
        deleteUserProfile
      }}>
      {props.children}
    </Context.Provider>
  );
}
export default AuthContext;
export const useAuth = () => React.useContext(Context)
