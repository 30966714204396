import React, { FC, ReactNode, useState } from 'react'

export const ReadMore: FC<{ children: ReactNode }> = (props) => {
    const text = props.children as string;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 400) : text}
            <span onClick={toggleReadMore} style={{color:'blue', cursor: 'pointer'}} >
                {isReadMore ? " ...mehr anzeigen" : " ...weniger anzeigen"}
            </span>
        </p>
    );
};