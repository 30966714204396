import { async } from "@firebase/util";
import axiosInstance from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { SpielewochenendeDTO } from "../models";

export const getSpielewochenende = async (): Promise<SpielewochenendeDTO> => {
    return await axiosInstance.get('Spielewochenende/GetSpielewochenende')
        .then(response => {
            return response.data as SpielewochenendeDTO;
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const createSpielewochenende = async (spielewochenende: SpielewochenendeDTO): Promise<SpielewochenendeDTO> => {
    return await axiosInstance.post('Spielewochenende/CreateSpielewochenende', spielewochenende)
        .then(response => {
            return response.data as SpielewochenendeDTO;
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const deleteSpielewochenende = async (): Promise<void> => {
    await axiosInstance.delete('Spielewochenende/DeleteSpielewochenende')
        .catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const updateSpielewochenende = async (spielewochenende: SpielewochenendeDTO): Promise<SpielewochenendeDTO> => {
    return await axiosInstance.put('Spielewochenende/UpdateSpielewochenende', spielewochenende)
        .then(response => {
            return response.data as SpielewochenendeDTO;
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
    }

    export const downloadBookingsAsPDF = async () => {
        return await axiosInstance.get('Spielewochenende/GetPDFsOfSpielewochenende', {
            timeout: Infinity,
            responseType: 'blob'
        }).then(response => {
            return new Blob([response.data], {
                type: response.headers.getContentType as string
            });
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
        })
    }