import React, { Dispatch, SetStateAction, useState } from "react";
import { FC, ReactNode } from "react";
import { ErrorNotification } from "../configuration/notifications";
import { NikolausaktionBookingDTO, NikolausaktionDTO } from "../models";
import * as service from "../services/NikolausaktionBookingService"


interface NikolausaktionBookingContextInterface {
    loading: boolean,
    createNikolausaktionBooking: (booking: NikolausaktionBookingDTO) => Promise<NikolausaktionBookingDTO>
    updateNikolausaktionBooking: (booking: NikolausaktionBookingDTO) => Promise<NikolausaktionBookingDTO>
    getNikolausaktionBookingsForUser: () => Promise<NikolausaktionBookingDTO[]>
    getAllNikolausaktionBookings: () => Promise<NikolausaktionBookingDTO[]>
    deleteNikolausaktionBooking: (id: string) => Promise<void>
    deleteNikolausaktionBookingAsAdmin: (id: string, emailText: string) => Promise<void>
    downloadBookingsAsPDF: () => Promise<Blob>
    setLoading: Dispatch<SetStateAction<boolean>>
  }
  
  
  const Context = React.createContext<NikolausaktionBookingContextInterface>({
    loading: false,
    createNikolausaktionBooking: () => new Promise<NikolausaktionBookingDTO>(() => {}),
    updateNikolausaktionBooking: () => new Promise<NikolausaktionBookingDTO>(() => {}),
    getNikolausaktionBookingsForUser: () => new Promise<NikolausaktionBookingDTO[]>(() => {}),
    getAllNikolausaktionBookings: () => new Promise<NikolausaktionBookingDTO[]>(() => {}),
    deleteNikolausaktionBooking: () => new Promise<void>(() => {}),
    deleteNikolausaktionBookingAsAdmin: () => new Promise<void>(() => {}),
    downloadBookingsAsPDF: () => new Promise<Blob>(() => {}),
    setLoading: () => {}
  });

  const NikolausaktionBookingContext: FC<{ children: ReactNode }> = (props) => {

    const [loading, setLoading] = useState(false)

    const createNikolausaktionBooking = async (booking: NikolausaktionBookingDTO): Promise<NikolausaktionBookingDTO> => {
        setLoading(true)
        const response = await service.createNikolausaktionBooking(booking);
        return response as NikolausaktionBookingDTO
    }

    const updateNikolausaktionBooking = async (booking: NikolausaktionBookingDTO): Promise<NikolausaktionBookingDTO> => {
      setLoading(true)
      const response = await service.updateNikolausaktionBooking(booking);
      return response as NikolausaktionBookingDTO
  }

    const getNikolausaktionBookingsForUser = async (): Promise<NikolausaktionBookingDTO[]> => {
      setLoading(true)
      const response = await service.getNikolausaktionBookingsForUser();
      return response as NikolausaktionBookingDTO[]
    }

    const getAllNikolausaktionBookings = async (): Promise<NikolausaktionBookingDTO[]> => {
      setLoading(true)
      const response = await service.getAllNikolausaktionBookings()
      return response as NikolausaktionBookingDTO[];   
    }

    const downloadBookingsAsPDF = async (): Promise<Blob> => {
      setLoading(true)
      const response = await service.downloadBookingsAsPDF();
      return response as Blob;
  }

    const deleteNikolausaktionBooking = async (id: string): Promise<void> => {
      setLoading(true)
      const response = await service.deleteNikolausaktionBooking(id);
      return response;
    }

    const deleteNikolausaktionBookingAsAdmin = async (id: string, emailText: string): Promise<void> => {
      setLoading(true)
      const response = await service.deleteNikolausaktionBookingAsAdmin(id, emailText);
      return response;
    }

    return (
        <Context.Provider
        value={{
          loading,
          setLoading,
          createNikolausaktionBooking,
          getNikolausaktionBookingsForUser,
          updateNikolausaktionBooking,
          getAllNikolausaktionBookings,
          deleteNikolausaktionBooking,
          deleteNikolausaktionBookingAsAdmin,
          downloadBookingsAsPDF
        }}>
        {props.children}
      </Context.Provider>
    )
  }
  export default NikolausaktionBookingContext
  export const useNikolausaktionBooking = () => React.useContext(Context)