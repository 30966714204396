import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, TextField, Table, Container, Button, useMediaQuery } from "@mui/material";
import { time } from "console";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Meeting } from "../../models";



interface Props {
    key: number,
    day: string | undefined
    meetings: Meeting[],
    setDays: Dispatch<SetStateAction<Map<string | undefined, Meeting[]>>>
    disabled: boolean

}

export const CRUDMeeting: FC<Props> = (props) => {

    const mediaquery = useMediaQuery("(max-width: 700px)");

    const handleTimeDelete = (key: number) => {
        props.setDays((prevDays) => {
            const newMap = new Map(prevDays);
            const removedMeetingArray = newMap.get(props.day)!.filter((meeting, index) =>
                key !== index
            )
            newMap.set(props.day, removedMeetingArray)
            return newMap;
        })
    }

    const handleAmountUpdate = (key: number, amount: number) => {
        props.setDays((prevDays) => {
            const newMap = new Map(prevDays);
            const updatedMeetingArray = newMap.get(props.day)!.map((meeting, index) =>
               index === key ? { ...meeting, amount: amount } : meeting
            ) as Meeting[]
            newMap.set(props.day, updatedMeetingArray)
            return newMap;
        })
    }

    const handleTimeSpanUpdate = (key: number, timeSpan: string) => {
        props.setDays((prevDays) => {
            const newMap = new Map(prevDays);
            const updatedMeetingArray = newMap.get(props.day)!.map((meeting, index) =>
               index === key ? { ...meeting, timeSpan: timeSpan } : meeting
            ) as Meeting[]
            newMap.set(props.day, updatedMeetingArray)
            return newMap;
        })
    }


    return (<Container key={props.key}>
        <h5>{props.day}</h5>
        <TableContainer component={Paper} sx={{ width: mediaquery ? '100%' : '90%'}} >
            <Table sx={{ color: 'grey', width: '100%' }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Tag</TableCell>
                        <TableCell align="right">Anzahl der Termine</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.meetings.map((meeting, key) => {
                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row"><TextField
                                        required
                                        id="outlined-required"
                                        value={meeting.timeSpan}
                                        onChange={(value) => handleTimeSpanUpdate(key,value.currentTarget.value)}
                                    /></TableCell>
                                    <TableCell align="right"><TextField
                                        required
                                        type={'number'}
                                        value={meeting.amount}
                                        onChange={(value) => handleAmountUpdate(key,Number.parseInt(value.currentTarget.value))}
                                        id="outlined-required"
                                    /></TableCell>
                                    <TableCell align="right"><Button variant="contained" onClick={() => handleTimeDelete(key)} disabled={props.disabled}>Zeit löschen</Button></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <br></br>
    </Container>)
}