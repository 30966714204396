import { AktionChildDTO, SpielewochenendeBookingDTO, SpielewochenendeBookingFormState } from "../models";

export function getSpielewochenendeBookingDTO(spielewochenendeBooking: SpielewochenendeBookingFormState, children: AktionChildDTO[]): SpielewochenendeBookingDTO {
    let dto = {} as SpielewochenendeBookingDTO
    dto.parent = spielewochenendeBooking.parent
    dto.address = spielewochenendeBooking.address
    dto.children = children
    dto.userId = spielewochenendeBooking.userId
    dto.id = spielewochenendeBooking.id
    return dto
}

export function getSpielewochenendeBookingFormState(spielewochenendeBookingDTO: SpielewochenendeBookingDTO): SpielewochenendeBookingFormState {
    let formState = {} as SpielewochenendeBookingFormState
    formState.parent = spielewochenendeBookingDTO.parent;
    formState.id = spielewochenendeBookingDTO.id;
    formState.address = spielewochenendeBookingDTO.address;
    formState.userId = spielewochenendeBookingDTO.userId

    return formState;
}