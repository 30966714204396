import axios from "axios";
import axiosInstance from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { EventDTO } from "../models";

export const getEvents = async (): Promise<EventDTO[]> => {

    return await axiosInstance.get('Event/GetEvents')
        .then(response => {
            return response.data as EventDTO[];
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}


export const createEvent = async (event: EventDTO): Promise<EventDTO> => {
    return await axiosInstance.post('Event/CreateEvent', event)
        .then(response => {
            return response.data as EventDTO;
        }).catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const deleteEvent = async (eventId: string): Promise<void> => {
    await axiosInstance.delete('Event/DeleteEvent/' + eventId)
        .catch(reason => {
            if (reason.response !== undefined) {
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            } else {
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}


export const downloadICSFile = async () => {
    return await axiosInstance.get('Event/DownloadICSFile', {
        timeout: Infinity,
        responseType: 'blob'
    }).then(response => {
        return new Blob([response.data], { type: response.headers.getContentType as string });
    }).catch(reason => {
        if (reason.response !== undefined) {
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        } else {
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}