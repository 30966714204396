import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Meeting, NikolausaktionDTO } from "../../models";
import { useNikolausaktion } from "../../context/NikolausaktionContext";
import { CRUDMeeting } from "../Nikolaus/CRUDMeeting";
import { CreateMeetings } from "../Nikolaus/CreateMeetings";
import { NikolausBookings } from "../Nikolaus/NikolausBookings";
import TextSplitter from "../TextSplitter";
import DownloadIcon from "@mui/icons-material/Download";
import { useNikolausaktionBooking } from "../../context/NikolausaktionBookingContext";
import { ErrorNotification } from "../../configuration/notifications";

export const NikolausAdmin: FC = () => {
  const [value, setValue] = React.useState<Dayjs>(dayjs(Date.now()));
  const [active, setActive] = React.useState<boolean>(false);
  const [days, setDays] = useState<Map<string | undefined, Meeting[]>>(
    new Map()
  );
  const [disableAddNikolausationButton, setDisableAddNikolausationButton] =
    useState<boolean>(true);

  const mediaquery = useMediaQuery("(max-width: 700px)");

  const nikolausAktionContext = useNikolausaktion();
  const { downloadBookingsAsPDF, loading } = useNikolausaktionBooking();

  useEffect(() => {
    nikolausAktionContext
      .getNikolausaktion()
      .then((response) => {
        if(response.meetings!==undefined){
            setActive(response.active as boolean);
            setDays(groupBy(response.meetings, (x) => x.date));
            setDisableAddNikolausationButton(true);
        }else{
            setDisableAddNikolausationButton(false);
        }
        
      })
      .catch((reason) => {
        setDisableAddNikolausationButton(false);
        ErrorNotification(reason.statusCode, reason.message);
      })
      .finally(() => {
        nikolausAktionContext.setLoading(false);
      });
  }, []);

  const handleChange = (newValue: Dayjs) => {
    setValue(newValue);
  };

  const addDay = () => {
    setDays((prevDays) => {
      const newMap = new Map(prevDays);
      return newMap.set(value.toDate().toLocaleDateString(), [] as Meeting[]);
    });
  };

  const deleteDay = (index: string | undefined) => {
    setDays((prevDays) => {
      const newMap = new Map(prevDays);
      newMap.delete(index);
      return newMap;
    });
  };

  const createNikolausAktion = () => {
    let nikolausAktion = getNikolausAktionDTO();
    nikolausAktionContext
      .createNikolausaktion(nikolausAktion)
      .then((response) => {
        setDays(groupBy(response.meetings, (x) => x.date));
        setActive(response.active);
        setDisableAddNikolausationButton(true);
      })
      .finally(() => {
        nikolausAktionContext.setLoading(false)
      })
  };

  const updateNikolausAktion = () => {
    let nikolausAktion = getNikolausAktionDTO();
    nikolausAktionContext
      .updateNikolausaktion(nikolausAktion)
      .then((response) => {
        setActive(response.active);
        setDays(groupBy(response.meetings, (x) => x.date));
      })
      .finally(() => {
        nikolausAktionContext.setLoading(false);
      });
  };

  const deleteNikolausAktion = () => {
    nikolausAktionContext
      .deleteNikolausaktion()
      .then(() => {
        setDays(new Map());
        setActive(false);
        setDisableAddNikolausationButton(false);
      })
      .finally(() => {
        nikolausAktionContext.setLoading(false);
      });
  };

  const downloadBookings = () => {
    downloadBookingsAsPDF()
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", "nikolausaktion.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .finally(() => {
        nikolausAktionContext.setLoading(false);
      });
  };

  function getNikolausAktionDTO() {
    let nikolausAktion = {} as NikolausaktionDTO;
    nikolausAktion.active = active;
    let meetings = [] as Meeting[];
    days.forEach((value, key) => {
      value.forEach((meeting) => {
        meeting.date = key;
        meetings.push(meeting);
      });
    });
    nikolausAktion.meetings = meetings;
    return nikolausAktion;
  }

  function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
      var key = grouper(item);
      if (!store.has(key)) {
        store.set(key, [item]);
      } else {
        store.get(key)!.push(item);
      }
      return store;
    }, new Map<K, V[]>());
  }

  const getAmountOfTermin = (date: string | undefined): number => {
    //Wrong
    let amoutArray = days
      .get(date)!
      .map((meeting) => Number.parseInt(meeting.amount!)) as number[];
    return amoutArray.reduce((sum, current) => sum + current, 0);
  };

  return (
    <>
      {nikolausAktionContext.loading === true ? (
        <CircularProgress />
      ) : (
        <>
          <Container sx={{ display: "inline-flex", alignItems: "center" }}>
            <Switch
              type={"checkbox"}
              checked={active}
              onChange={(value) => setActive(value.target.checked)}
            />
            <h3 style={{ margin: 0 }}>Nikolausaktion aktiv</h3>
            <br></br>
          </Container>
          <TextSplitter />
          <h3>Nikolausaktion Konfiguration</h3>
          <h4>Tage an denen die Nikolausaktion stattfindet.</h4>
          <br></br>
          <div style={{ display: "inline-flex", marginLeft: "4%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date desktop"
                inputFormat="DD.MM.YYYY"
                value={value}
                onChange={(newValue) => handleChange(newValue as Dayjs)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              sx={{ marginLeft: "3%", width: "200px" }}
              onClick={() => addDay()}
            >
              Tag hinzufügen
            </Button>
          </div>
          <br></br>
          {Array.from(days.keys()).length > 0 ? (
            <TableContainer component={Paper} sx={{ width: mediaquery ? '100%' : '90%' }}>
              <Table
                sx={{ color: "grey", width: "100%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Tag</TableCell>
                    <TableCell align="right">Anzahl der Termine</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from(days.keys()).map(
                    (date: string | undefined, index) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                        >
                          <TableCell component="th" scope="row">
                            {date}
                          </TableCell>
                          <TableCell align="right">
                            {getAmountOfTermin(date)}
                          </TableCell>

                          <TableCell align="center">
                            <Button
                              variant="contained"
                              sx={{ margin: "3%" }}
                              onClick={() => deleteDay(date)}
                            >
                              Tag löschen
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          <TextSplitter />
          <h4>Termine</h4>
          <br></br>
          {
            /*Nur anzeigen wenn überhaupt ein Tag angelegt ist */
            Array.from(days.keys()).length > 0
              ? Array.from(days.keys()).map((day, key) => {
                  return (
                    /*Wenn ein Tag Meetings hat */
                    days.get(day)!.length > 0 ? (
                      <CRUDMeeting
                        key={key}
                        day={day}
                        meetings={days.get(day)!}
                        setDays={setDays}
                        disabled={active}
                      ></CRUDMeeting>
                    ) : (
                      <CreateMeetings
                        day={day}
                        setDays={setDays}
                      ></CreateMeetings>
                    )
                  );
                })
              : null
          }
          <br></br>
          <br></br>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              onClick={() => createNikolausAktion()}
              disabled={disableAddNikolausationButton}
              sx={{ marginBottom: "1em" }}
            >
              Nikolausaktion hinzufügen
            </Button>
            <Button
              variant="contained"
              onClick={() => updateNikolausAktion()}
              disabled={!disableAddNikolausationButton}
              sx={{ marginBottom: "1em" }}
            >
              Nikolausaktion aktualiesieren
            </Button>
            <Button
              variant="contained"
              onClick={() => deleteNikolausAktion()}
              disabled={!disableAddNikolausationButton || active}
              sx={{ marginBottom: "1em" }}
            >
              Nikolausaktion löschen
            </Button>
          </Container>
          <TextSplitter />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Alle Anmeldungen</h4>
            <Tooltip title="Anmeldungen als PDF herunterladen">
              <IconButton onClick={() => downloadBookings()}>
                <DownloadIcon color="primary"></DownloadIcon>
              </IconButton>
            </Tooltip>
          </div>
          <NikolausBookings></NikolausBookings>
        </>
      )}
    </>
  );
};
