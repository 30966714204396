import axiosInstance from "../configuration/axios";
import { ErrorNotification } from "../configuration/notifications";
import { ZeltlagerBookingDTO } from "../models";

export const createZeltlagerBooking = async (zeltlagerBooking: ZeltlagerBookingDTO) => {
    return await axiosInstance.post('ZeltlagerBooking/CreatezeltlagerBooking', zeltlagerBooking)
    .then(response => {
        return response.data as ZeltlagerBookingDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const updateZeltlagerBooking = async (zeltlagerBooking: ZeltlagerBookingDTO) => {
    return await axiosInstance.put('ZeltlagerBooking/UpdateZeltlagerBooking', zeltlagerBooking)
    .then(response => {
        return response.data as ZeltlagerBookingDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const getZeltlagerBookingForUser = async () => {
    return await axiosInstance.get('ZeltlagerBooking/GetZeltlagerBookingForUser')
    .then(response => {
        return response.data as ZeltlagerBookingDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const deleteZeltlagerBookingAsAdmin = async (id: string, childId: string, emailText: string) => {
    var formData = new FormData();
    formData.append("emailText",emailText)
    await axiosInstance.post('ZeltlagerBooking/DeleteZeltlagerBookingAsAdmin/' + id +"/" +childId, formData)
    .catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    }) 
}

export const deleteZeltlagerBooking = async (id: string) => {
    await axiosInstance.delete('ZeltlagerBooking/DeleteZeltlagerBooking/' + id)
    .catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}

export const getAllZeltlagerBookings = async () => {
    return await axiosInstance.get(
        "ZeltlagerBooking/GetAllZeltlagerBookings"
    ).then(response => {
        return response.data as ZeltlagerBookingDTO[];
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}

