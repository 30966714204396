import {
  Paper,
  Grid,
  TextField,
  Box,
  Container,
  Button,
  useMediaQuery,
} from "@mui/material";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { NikolausChild } from "../../models";

interface Props {
  children: NikolausChild[];
  setChildren: (child: SetStateAction<NikolausChild[]>) => void;
  addPointToWellDone: (keyOfChild: number) => void;
  addPointToToImprove: (keyOfChild: number) => void;
  disableButtons: boolean
}
export const Child: FC<Props> = (props) => {
  const mediaquery = useMediaQuery("(max-width: 700px)");

  return (
    <>
      {props.children?.map((child, keyOfChild) => {
        return (
          <Paper
            sx={{
              border: "1x solid black",
              borderRadius: "20px",
              marginBottom: "10px",
              padding: "5%",
              backgroundColor: "lightgrey",
            }}
            key={keyOfChild}
          >
            <Grid container columnGap={3} rowGap={3}>
              <Grid item xs="auto">
                <TextField
                  required
                  id="nameOfChild"
                  value={child.name}
                  disabled={props.disableButtons}
                  label="Name des Kindes"
                  variant="outlined"
                  onChange={(value) => {
                    props.setChildren(
                      props.children.map((child, key) =>
                        key === keyOfChild
                          ? { ...child, name: value.target.value }
                          : child
                      )
                    );
                  }}
                ></TextField>
              </Grid>
              <Grid item>
                <TextField
                  required
                  id="email"
                  disabled={props.disableButtons}
                  value={child.age}
                  label="Alter des Kindes"
                  onChange={(value) => {
                    props.setChildren(
                      props.children.map((child, key) =>
                        key === keyOfChild
                          ? {
                              ...child,
                              age: Number.parseInt(value.target.value),
                            }
                          : child
                      )
                    );
                  }}
                  type="number"
                  variant="outlined"
                ></TextField>
              </Grid>
              <Button
                variant="outlined"
                onClick={() =>
                  props.setChildren(
                    props.children.filter((child, key) => key !== keyOfChild)
                  )
                }
                disabled={props.disableButtons}
              >
                Kind löschen
              </Button>
            </Grid>
            <br></br>
            <br></br>
            <div style={{ display: mediaquery ? "none" : "" }}>
              <div
                style={{
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "20px",
                }}
              >
                <div>Gut gemacht</div>
                <div>Zu verbessern</div>
              </div>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  border: "1px solid black",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  {child.wellDone?.map((point, keyOfPoint) => {
                    return (
                      <div
                        key={keyOfPoint}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          required
                          disabled={props.disableButtons}
                          label=""
                          value={point}
                          onChange={(value) => {
                            if (!!value) {
                              props.setChildren(
                                props.children.map((child, key) =>
                                  key === keyOfChild
                                    ? {
                                        ...child,
                                        wellDone: child.wellDone.map(
                                          (point, key) =>
                                            key === keyOfPoint
                                              ? value.target.value
                                              : point
                                        ),
                                      }
                                    : child
                                )
                              );
                            }
                          }}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                        <Button
                          variant="outlined"
                          sx={{ marginLeft: "20px" }}
                          disabled={props.disableButtons}
                          onClick={() =>
                            props.setChildren(
                              props.children.map((child, key) =>
                                key === keyOfChild
                                  ? {
                                      ...child,
                                      wellDone: child.wellDone.filter(
                                        (point, key) => key !== keyOfPoint
                                      ),
                                    }
                                  : child
                              )
                            )
                          }
                        >
                          Löschen
                        </Button>
                      </div>
                    );
                  })}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={props.disableButtons}
                      onClick={() => props.addPointToWellDone(keyOfChild)}
                    >
                      Punkt hinzufügen
                    </Button>
                  </Box>
                </div>
                <div>
                  {child.toImprove?.map((point, keyOfPoint) => {
                    return (
                      <div
                        key={keyOfPoint}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          required
                          label=""
                          disabled={props.disableButtons}
                          value={point}
                          onChange={(value) => {
                            if (!!value) {
                              props.setChildren(
                                props.children.map((child, key) =>
                                  key === keyOfChild
                                    ? {
                                        ...child,
                                        toImprove: child.toImprove.map(
                                          (point, key) =>
                                            key === keyOfPoint
                                              ? value.target.value
                                              : point
                                        ),
                                      }
                                    : child
                                )
                              );
                            }
                          }}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                        <Button
                          variant="outlined"
                          sx={{ marginLeft: "20px" }}
                          disabled={props.disableButtons}
                          onClick={() =>
                            props.setChildren(
                              props.children.map((child, key) =>
                                key === keyOfChild
                                  ? {
                                      ...child,
                                      toImprove: child.toImprove.filter(
                                        (point, key) => key !== keyOfPoint
                                      ),
                                    }
                                  : child
                              )
                            )
                          }
                        >
                          Löschen
                        </Button>
                      </div>
                    );
                  })}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      disabled={props.disableButtons}
                      onClick={() => props.addPointToToImprove(keyOfChild)}
                    >
                      Punkt hinzufügen
                    </Button>
                  </Box>
                </div>
              </Container>
            </div>
            <div style={{ display: mediaquery ? "" : "none" }}>
              <div
                style={{
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "20px",
                }}
              >
                <div>Gut gemacht</div>
              </div>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  border: "1px solid black",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  {child.wellDone?.map((point, keyOfPoint) => {
                    return (
                      <div
                        key={keyOfPoint}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          required
                          disabled={props.disableButtons}
                          label=""
                          value={point}
                          onChange={(value) => {
                            if (!!value) {
                              props.setChildren(
                                props.children.map((child, key) =>
                                  key === keyOfChild
                                    ? {
                                        ...child,
                                        wellDone: child.wellDone.map(
                                          (point, key) =>
                                            key === keyOfPoint
                                              ? value.target.value
                                              : point
                                        ),
                                      }
                                    : child
                                )
                              );
                            }
                          }}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                        <Button
                          variant="outlined"
                          sx={{ marginLeft: "20px" }}
                          disabled={props.disableButtons}
                          onClick={() =>
                            props.setChildren(
                              props.children.map((child, key) =>
                                key === keyOfChild
                                  ? {
                                      ...child,
                                      wellDone: child.wellDone.filter(
                                        (point, key) => key !== keyOfPoint
                                      ),
                                    }
                                  : child
                              )
                            )
                          }
                        >
                          Löschen
                        </Button>
                      </div>
                    );
                  })}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={props.disableButtons}
                      onClick={() => props.addPointToWellDone(keyOfChild)}
                    >
                      Punkt hinzufügen
                    </Button>
                  </Box>
                </div>
              </Container>
              <div
                style={{
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "20px",
                }}
              >
                <div>Zu verbessern</div>
              </div>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  border: "1px solid black",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  {child.toImprove?.map((point, keyOfPoint) => {
                    return (
                      <div
                        key={keyOfPoint}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          required
                          label=""
                          disabled={props.disableButtons}
                          value={point}
                          onChange={(value) => {
                            if (!!value) {
                              props.setChildren(
                                props.children.map((child, key) =>
                                  key === keyOfChild
                                    ? {
                                        ...child,
                                        toImprove: child.toImprove.map(
                                          (point, key) =>
                                            key === keyOfPoint
                                              ? value.target.value
                                              : point
                                        ),
                                      }
                                    : child
                                )
                              );
                            }
                          }}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                        <Button
                          variant="outlined"
                          sx={{ marginLeft: "20px" }}
                          disabled={props.disableButtons}
                          onClick={() =>
                            props.setChildren(
                              props.children.map((child, key) =>
                                key === keyOfChild
                                  ? {
                                      ...child,
                                      toImprove: child.toImprove.filter(
                                        (point, key) => key !== keyOfPoint
                                      ),
                                    }
                                  : child
                              )
                            )
                          }
                        >
                          Löschen
                        </Button>
                      </div>
                    );
                  })}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => props.addPointToToImprove(keyOfChild)}
                      disabled={props.disableButtons}
                    >
                      Punkt hinzufügen
                    </Button>
                  </Box>
                </div>
              </Container>
            </div>
            <br></br>
          </Paper>
        );
      })}
    </>
  );
};
