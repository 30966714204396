import React, { FC, ReactNode } from "react";
import AuthContext from "./AuthContext";
import NikolausaktionBookingContext from "./NikolausaktionBookingContext";
import EventContext from "./EventContext";
import NikolausaktionContext from "./NikolausaktionContext";
import ZeltlagerContext from "./ZeltlagerContext";
import DownloadContext from "./DownloadContext";
import ZeltlagerBookingContext from "./ZeltlagerBookingContext";
import SpielewochenendeContext from "./SpielewochenendeContext";
import SpielewochenendeBookingContext from "./SpielewochenendeBookingCOntext";

export const AppContext: FC<{ children: ReactNode }> = (props) => {
  return (
    <AuthContext>
      <EventContext>
        <ZeltlagerContext>
          <ZeltlagerBookingContext>
            <SpielewochenendeContext>
              <SpielewochenendeBookingContext>
                <NikolausaktionContext>
                  <NikolausaktionBookingContext>
                    <DownloadContext>
                      {props.children}
                    </DownloadContext>
                  </NikolausaktionBookingContext>
                </NikolausaktionContext>
              </SpielewochenendeBookingContext>
            </SpielewochenendeContext>
          </ZeltlagerBookingContext>
        </ZeltlagerContext>
      </EventContext>
    </AuthContext>
  );
};
export default AppContext;
