import { Grid, TextField, Checkbox, Paper, InputLabel, Select, MenuItem, Box, Container, Button, FormControl, Tooltip, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { AktionChildDTO, ZeltlagerBookingFormState, ZeltlagerDTO } from "../../models";
import Datenschutz from "../Anmeldung/Datenschutz";
import TextSplitter from "../TextSplitter";
import { AktionsChild } from "./AktionsChild";
import DirectionsIcon from '@mui/icons-material/Directions';

interface Props {
    zeltlagerBooking: ZeltlagerBookingFormState
    setZeltlagerBooking: Dispatch<SetStateAction<ZeltlagerBookingFormState>>
    children: AktionChildDTO[]
    setChildren: Dispatch<SetStateAction<AktionChildDTO[]>>
    checkBoxCarCapacityChecked: boolean,
    setCheckBoxCarCapacityChecked: Dispatch<SetStateAction<boolean>>
    addChild: () => void
    updateBooking: () => void
    deleteBooking: () => void
    zeltlager: ZeltlagerDTO
}

export const UpdateZeltlagerBooking: FC<Props> = (props) => {


    return (
        <Container>
            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                <h1>Zeltlager Daten generell</h1>
            </Grid>
            <br />
            <Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Wann</TableCell>
                                <TableCell align="center">Wo?</TableCell>
                                <TableCell align="center">Preis (in Euro)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.zeltlager.endDate !== undefined && props.zeltlager?.endDate !== null
                                    && props.zeltlager.startDate !== undefined && props.zeltlager.startDate !== null ? (<TableRow>
                                        <TableCell align="center">{new Date(props.zeltlager.startDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })} - {new Date(props.zeltlager.endDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}</TableCell>
                                        <TableCell align="center"> {props.zeltlager.location}
                                            <a href={props.zeltlager.locationLink} target="_blank" style={{ color: "green" }} rel="noreferrer">
                                                <DirectionsIcon></DirectionsIcon>
                                            </a>
                                        </TableCell>
                                        <TableCell align="center">{props.zeltlager.price}</TableCell>

                                    </TableRow>) : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <br/>
            <p>Hier können Sie nun Ihre Zeltlageranmeldung bearbeiten! Drücken Sie unbeding auf "Zeltlagerbuchung aktualisieren", andernfalls werden die Änderungen nicht übernommen</p>
            <p>Bei Fragen wenden Sie nicht bitte an website@pfarrjugendneusaess.de</p>
            <br></br>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="Storniert die gesamte Anmeldung und somit alle Kinder. Falls nur eine Kind storniert werden soll, einfach das Kind löschen und die Anmeldung aktualisieren." placement="top" arrow>
                    <Button variant="contained" onClick={props.deleteBooking}>Stornieren</Button>
                </Tooltip>
            </div>
            <h4>Elternteil</h4>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        label="Vorname"
                        value={props.zeltlagerBooking.parent?.firstName}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        onChange={(newText) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, parent: { ...props.zeltlagerBooking.parent, firstName: newText.target.value } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastname"
                        label="Nachname"
                        value={props.zeltlagerBooking.parent?.lastName}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        onChange={(newText) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, parent: { ...props.zeltlagerBooking.parent, lastName: newText.target.value } })
                        }}
                    ></TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phoneNumber"
                        label="Telefonnumer"
                        value={props.zeltlagerBooking.parent?.phoneNumber}
                        type="number"
                        fullWidth
                        autoComplete="phonenumber"
                        variant="outlined"
                        onChange={(newText) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, parent: { ...props.zeltlagerBooking.parent, phoneNumber: newText.target.value } })
                        }}
                    ></TextField>
                </Grid>

                <Grid item xs={12} sm={2.5} marginTop={1}>
                    <Checkbox checked={props.checkBoxCarCapacityChecked} onChange={(value) => props.setCheckBoxCarCapacityChecked(value.target.checked)} />
                    Ich kann fahren
                </Grid>
                <Grid item xs={12} sm={3.5}>
                    <TextField
                        id="carCapacity"
                        label="Auto Kapazität"
                        type="number"
                        fullWidth
                        value={props.zeltlagerBooking.parent?.carCapacity}
                        autoComplete="carcapacity"
                        variant="outlined"
                        disabled={!props.checkBoxCarCapacityChecked}
                        onChange={(newText) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, parent: { ...props.zeltlagerBooking.parent, carCapacity: Number.parseInt(newText.target.value) } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        required
                        id="street"
                        label="Straße"
                        value={props.zeltlagerBooking.address?.street}
                        fullWidth
                        autoComplete="street"
                        variant="outlined"
                        onChange={(value) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, address: { ...props.zeltlagerBooking.address, street: value.target.value } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        id="houseNumber"
                        label="Hausnummer"
                        value={props.zeltlagerBooking.address?.houseNumber}
                        fullWidth
                        autoComplete="houseNumber"
                        variant="outlined"
                        onChange={(value) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, address: { ...props.zeltlagerBooking.address, houseNumber: value.target.value } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="plz"
                        label="Postleitzahl"
                        fullWidth
                        value={props.zeltlagerBooking.address!.plz}
                        type="number"
                        autoComplete="plz"
                        variant="outlined"
                        onChange={(value) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, address: { ...props.zeltlagerBooking.address, plz: Number.parseInt(value.target.value) } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="city"
                        label="Stadt"
                        value={props.zeltlagerBooking.address?.city}
                        fullWidth
                        autoComplete="city"
                        variant="outlined"
                        onChange={(value) => {
                            props.setZeltlagerBooking({ ...props.zeltlagerBooking, address: { ...props.zeltlagerBooking.address, city: value.target.value } })
                        }}
                    ></TextField>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
                <Grid >
                    {
                        props.children.map((child, key) => {
                            return <AktionsChild children={props.children} setChildren={props.setChildren} child={child} keyOfChild={key} disableButtons={false}></AktionsChild>
                        })
                    }
                </Grid>
            </Grid>
            <br />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={props.addChild}>Kind hinzufügen</Button></Box>
            <TextSplitter />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={props.updateBooking}>Zeltlageranmeldung aktualisieren</Button></Box>
        </Container>
    )
}