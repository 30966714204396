import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { FirebaseError } from 'firebase/app';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="/">
        Pfarrjugend Neusäß
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {

  const auth = useAuth()
  const [error,setError] = useState<string>("");

  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    auth.login(data.get("email") as string, data.get("password") as string).then(user => {
      if(!user.user.emailVerified){
        auth.logout();
        setError("Sie müssen zuerst ihre E-Mailadresse bestätigen!")
      }else{
        navigate('/')
      }
    }).catch(error => {
      switch(error.code){
        case 'auth/wrong-password':
            setError("Das Email oder das Passwort ist falsch");
            break;
        case 'auth/user-not-found':
            setError('Für diese E-Mailadresse existiert kein Konto. Bitte registrieren Sie sich!')
      }
    })
   
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Bitte melden sie sich an
          </Typography>
          {
            error.length>0 ? (
              <Alert severity="error">{error}</Alert>
            ) : (null)
          }
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Adresse"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Anmelden
              </Button>

            <Grid container xs={20} spacing={1}>
              <Grid item xs={10}>
                <Link to="/forgotPassword" >
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item xs={10}>
                <Link to="/signup">
                  {"Sie haben noch kein Konto ? Registrieren"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  );
}