import React, { Dispatch, SetStateAction, useState } from "react";
import { FC, ReactNode } from "react";
import { ZeltlagerBookingDTO } from "../models";
import { downloadBookingsAsPDF } from "../services/NikolausaktionBookingService";
import * as service from "../services/ZeltlagerBookingService"

interface ZeltlagerBookingContextInterface {
    loading: boolean,
    createZeltlagerBooking: (booking: ZeltlagerBookingDTO) => Promise<ZeltlagerBookingDTO>,
    updateZeltlagerBooking: (booking: ZeltlagerBookingDTO) => Promise<ZeltlagerBookingDTO>,
    getZeltlagerBookingForUser: () => Promise<ZeltlagerBookingDTO>,
    deleteZeltlagerBookingAsAdmin: (id: string, emailText: string, childId: string) => Promise<void>
    getAllZeltlagerBookings: () => Promise<ZeltlagerBookingDTO[]>
    deleteZeltlagerBooking: (id:string) => Promise<void>
    setLoading: Dispatch<SetStateAction<boolean>>
}

const Context = React.createContext<ZeltlagerBookingContextInterface>({
    loading: false,
    createZeltlagerBooking: () => new Promise<ZeltlagerBookingDTO>(() => { }),
    updateZeltlagerBooking: () => new Promise<ZeltlagerBookingDTO>(() => { }),
    getZeltlagerBookingForUser: () => new Promise<ZeltlagerBookingDTO>(() => { }),
    deleteZeltlagerBookingAsAdmin: () => new Promise<void>(() => {}),
    getAllZeltlagerBookings: () => new Promise<ZeltlagerBookingDTO[]>(() => {}),
    deleteZeltlagerBooking: (id:string) => new Promise<void>(() => {}),
    setLoading: () => {}

});

const ZeltlagerBookingContext: FC<{ children: ReactNode }> = (props) => {

    const [loading, setLoading] = useState(false)

    const createZeltlagerBooking = async (booking: ZeltlagerBookingDTO): Promise<ZeltlagerBookingDTO> => {
        setLoading(true)
        const response = await service.createZeltlagerBooking(booking);
        return response as ZeltlagerBookingDTO
    }

    const updateZeltlagerBooking = async (booking: ZeltlagerBookingDTO): Promise<ZeltlagerBookingDTO> => {
        setLoading(true)
        const response = await service.updateZeltlagerBooking(booking);
        return response as ZeltlagerBookingDTO
    }

    const getZeltlagerBookingForUser = async () => {
        setLoading(true)
        const response = await service.getZeltlagerBookingForUser();
        return response as ZeltlagerBookingDTO
    }

    const deleteZeltlagerBookingAsAdmin =async (id:string, childId: string, emailText: string): Promise<void> => {
        setLoading(true)
        return await service.deleteZeltlagerBookingAsAdmin(id, childId, emailText)
    }

    const deleteZeltlagerBooking =async (id:string): Promise<void> => {
        setLoading(true)
        return await service.deleteZeltlagerBooking(id)
    }
    
    const getAllZeltlagerBookings =async (): Promise<ZeltlagerBookingDTO[]> => {
        setLoading(true)
        const response = await service.getAllZeltlagerBookings();
        return response as ZeltlagerBookingDTO[]
    }
    
   
    return (
        <Context.Provider
            value={{
                loading,
                setLoading,
                createZeltlagerBooking,
                updateZeltlagerBooking,
                getZeltlagerBookingForUser,
                deleteZeltlagerBookingAsAdmin,
                deleteZeltlagerBooking,
                getAllZeltlagerBookings,
            }}>
                {props.children}
            </Context.Provider>
    )
}
export default ZeltlagerBookingContext
export const useZeltlagerBooking = () => React.useContext(Context)