import { Box, Button, Container } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
}

export default function CheckAnmeldung({ }: Props) {
    return (

        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <p>Sie sind noch nicht angemeldet! Bitte melden Sie sich an oder erstellen Sie sich ein Konto!</p>
            </Box>
            <br></br>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Link to='/login'>
                    <Button variant='contained'>Anmelden</Button>
                </Link>
                <Link to='/signup'>
                    <Button variant='contained'>Registrieren</Button>
                </Link>
            </Box>
            <br></br>
            <br></br>
            <br></br>
        </Container>
    )
}