import { AddressDTO, AktionChildDTO, ParentDTO, ZeltlagerBookingDTO, ZeltlagerBookingFormState } from "../models";

export function getZeltlagerBookingDTO(zeltlagerBooking: ZeltlagerBookingFormState, children: AktionChildDTO[]): ZeltlagerBookingDTO {
    let dto = {} as ZeltlagerBookingDTO
    dto.parent = zeltlagerBooking.parent
    dto.address = zeltlagerBooking.address
    dto.children = children
    dto.userId = zeltlagerBooking.userId
    dto.id = zeltlagerBooking.id
    return dto;
}

export function getZeltlagerBookingFormState(zeltlagerBookingDto: ZeltlagerBookingDTO): ZeltlagerBookingFormState{
    let formState = {} as ZeltlagerBookingFormState
    formState.parent = zeltlagerBookingDto.parent;
    formState.id = zeltlagerBookingDto.id;
    formState.address = zeltlagerBookingDto.address;
    formState.userId = zeltlagerBookingDto.userId
    formState.id = zeltlagerBookingDto.id
    return formState;
}
