import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { ZeltlagerDTO } from "../models";
import * as service from "../services/ZeltlagerService"

interface ZeltlagerContextInterface {
    loading: boolean,
    getZeltlager: () => Promise<ZeltlagerDTO>
    createZeltlager: (zeltlager: ZeltlagerDTO) => Promise<ZeltlagerDTO>
    deleteZeltlager: (zeltlagerId: string) => Promise<void>
    updateZeltlager: (zeltlager: ZeltlagerDTO) => Promise<ZeltlagerDTO>
    downloadBookingsAsPDF: () => Promise<Blob>
    setLoading: Dispatch<SetStateAction<boolean>>
    sendReminderEamil: () => Promise<void>
}

const Context = React.createContext<ZeltlagerContextInterface>({
    loading: false,
    getZeltlager: () => new Promise<ZeltlagerDTO>(() => {}),
    createZeltlager: (zeltlager: ZeltlagerDTO) => new Promise<ZeltlagerDTO>(() => {}),
    deleteZeltlager: (zeltlagerId: string) => new Promise<void>(() => {}),
    updateZeltlager: (zeltlager: ZeltlagerDTO) => new Promise<ZeltlagerDTO>(() => {}),
    downloadBookingsAsPDF: () => new Promise<Blob>(() => {}),
    setLoading: () => {},
    sendReminderEamil: () => new Promise<void>(() => {})

});

const ZeltlagerContext : FC<{ children: ReactNode }> = (props) => {
    const [loading, setLoading] = useState(true)

    const getZeltlager =async (): Promise<ZeltlagerDTO> => {
        setLoading(true)
        const response = await service.getZeltlager();
        return response as ZeltlagerDTO;
    }

    const createZeltlager =async (zeltlager: ZeltlagerDTO): Promise<ZeltlagerDTO> => {
        setLoading(true)
        const response = await service.createZeltlager(zeltlager);
        return response as ZeltlagerDTO
    }

    const deleteZeltlager =async (): Promise<void> => {
        setLoading(true)
        const response = await service.deleteZeltlager();
        return response;
    }
    const updateZeltlager =async (zeltlager: ZeltlagerDTO): Promise<ZeltlagerDTO> => {
        setLoading(true)
        const response = await service.updateZeltlager(zeltlager);
        return response as ZeltlagerDTO
    }
    const downloadBookingsAsPDF = async (): Promise<Blob> => {
        setLoading(true)
        const response = await service.downloadBookingsAsPDF();
        return response as Blob;
    }

    const sendReminderEamil = async (): Promise<void> => {
       await service.sendReminderEmail();
    }
    
    return (
        <Context.Provider
        value = {{
            loading,
            setLoading,
            getZeltlager,
            createZeltlager,
            deleteZeltlager,
            updateZeltlager,
            downloadBookingsAsPDF,
            sendReminderEamil
        }}>
            {props.children}
        </Context.Provider>
    )
}
export default ZeltlagerContext;
export const useZeltlager = () => React.useContext(Context)