import React, { FC, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import Menu from '../componets/AppBar'
import Footer from "../componets/Footer";
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from "@mui/material";

export const MainLayout: FC<{children: ReactNode}> = (props) => {
    
    const mediaquery = useMediaQuery('(max-width: 600px)')
    
    return ( 
       <>
        <Menu></Menu>
        <br></br>
        <br></br>
        <br></br>
        <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="light"
              />
        {props.children}
        {
            mediaquery == true ? (
                <>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                </>
            ) : (null)
        }
        <Footer></Footer>
        </>
    )
} 