import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { maxHeaderSize } from 'http';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigateToTab = (route: string) => {
    navigate(route)
  }


  const drawer = (
    <>
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Pfarrjugend Neusäß
      </Typography>
      <Divider color='black'/>
      <List>
        { 
          (auth.currentUser?.email !== undefined || null) && (auth.currentUser?.email === "johannes@schregles.de" || auth.currentUser?.email === "maxc@kabelmail.de" || auth.currentUser?.email === "website@pfarrjugendneusaess.de") ? (
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/adminDashboard')}>
                <ListItemText primary='Admindashboard' />
              </ListItemButton>
            </ListItem>
          ) : (null)
        }
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/')}>
            <ListItemText primary='Home' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/termine')}>
            <ListItemText primary='Termine' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{textAlign: 'center'}} onClick={() => navigateToTab('/gruppenstunden')}>
            <ListItemText primary='Gruppenstunden' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/nikolaus')}>
            <ListItemText primary='Nikolausaktion' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/zeltlager')}>
            <ListItemText primary='Zeltlager' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/spielewochenende')}>
            <ListItemText primary='Spielewochende' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/downloads')}>
            <ListItemText primary='Downloads' />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
        {auth.currentUser === undefined || auth.currentUser == null ? (
              <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/login')}>
              <ListItemText primary='Anmelden / Registrieren' />
            </ListItemButton>
            ) : (
              <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigateToTab('/user')}>
              <ListItemText primary={auth.currentUser.displayName} />
            </ListItemButton>
            )
        }
        </ListItem>
      </List>
    </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar sx={{backgroundColor: 'rgb(76,187,23)', color: 'black', display: 'flex', flexDirection: 'row', justifyContent: { xs: 'flex-start', sm: 'space-between' }}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            align='left'
            sx={{cursor: 'pointer'}}
            onClick={() => navigate("/")}
          >
            Pfarrjugend Neusäß
          </Typography>
          <Box sx={{  display: { xs: 'none', sm: 'flex' }, flexWrap: 'wrap'}}>
            {
              (auth.currentUser?.email !== undefined || null) && (auth.currentUser?.email === "johannes@schregles.de" || auth.currentUser?.email === "maxc@kabelmail.de" || auth.currentUser?.email === "website@pfarrjugendneusaess.de") ? (
                  <Box>
                  <Button sx={{ color: 'black'}} onClick={() => navigateToTab('/adminDashboard')}>
                  Admin-Dashboard
                </Button>
                  </Box>
              ) : (null)
            }
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/')}>
              Home
            </Button>
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/termine')}>
              Termine
            </Button>
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/gruppenstunden')}>
              Gruppenstunden
            </Button>
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/nikolaus')}>
              Nikolausaktion
            </Button>
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/zeltlager')}>
              Zeltlager
            </Button>
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/spielewochenende')}>
              Spielewochenende
            </Button>
            <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/downloads')}>
              Downloads
            </Button>
            {auth.currentUser === undefined || auth.currentUser == null ? (
              <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/login')}>
                Anmelden /Registrieren
              </Button>
            ) : (
              <Button sx={{ color: 'black' }} onClick={() => navigateToTab('/user')}>
                {auth.currentUser.displayName}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
