import { Paper, Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Slide, CircularProgress } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC, useEffect, useState } from "react";
import { Form, ProgressBar } from 'react-bootstrap';
import { useDownload } from "../../context/DownloadContext";
import { DownloadDTO } from "../../models";
import { v4 as uuidv4 } from "uuid";
import { ErrorNotification, SuccessNotification } from "../../configuration/notifications";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const chunkSize = 1048576 * 3;//its 3MB, increase the number measure in mb

export const DownloadsAdmin: FC = () => {

    const [downloads, setDownloads] = useState<DownloadDTO[]>([])
    const [name, setName] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [actualFileName, setActualFileName] = useState("")
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({} as any)
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [progress, setProgress] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)
    const downloadContext = useDownload();


    const progressInstance = <ProgressBar animated now={progress} label={`${progress.toFixed(3)}%`} />;

    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
    }, [progress])
 

    const getFileContext = (e: any) => {
        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        setActualFileName(_file.name)

        const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)

        setFileToBeUpload(_file)
        const _fileID = uuidv4() + "." + _file.name.split('.').pop();
        setFileGuid(_fileID)
    }


    const fileUpload = (counter: number) => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk)
        }
    }

    const uploadChunk = async (chunk: any) => {
        try {
            const response = await downloadContext.uploadChunk(chunk,counter,fileGuid)
            const data = response.data;
            if (data.isSuccess) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter == chunkCount) {

                    await uploadCompleted();
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                console.log('Error Occurred:', data.errorMessage)
            }

        } catch (error) {
            console.log('error', error)
        }
    }

    const uploadCompleted = async () => {
        var formData = new FormData();
        formData.append('actualFileName', actualFileName);
        formData.append('password', password)
        formData.append('name', name)
        setProgress(100);
        const response = await downloadContext.uploadCompleted(fileGuid,formData)

        const data = response.data;
        if (data.isSuccess) {
            handleClose();
            getDownloads()
        }
    
    }

    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetChunkProperties();
        setShowProgress(false)
        setName("")
        setPassword("")
        setFileSize(0)
    };



    const handleDelete = (id: string) => {
        downloadContext.deleteDownload(id).then(result => {
            setDownloads(downloads.filter(download => download.id !== id))
            SuccessNotification("Der Download wurde erfolgreich gelöscht!")
        }).catch(reason => {
            ErrorNotification(reason.statusCode,reason.message)
        }).finally(() => {
            downloadContext.setLoading(false)
        })
    }

    useEffect(() => {
        getDownloads()
    }, [])

    function getDownloads() {
        downloadContext.getDownloads().then(result => {
            setDownloads(result)
        }).catch(reason => {
            ErrorNotification(reason.statusCode,reason.message)
        }).finally(() => {
            downloadContext.setLoading(false)
        })
    }


    return (
        <>
            {
                downloadContext.loading === true ? (<CircularProgress/>) : (
                    downloads.length > 0 ? (
                        downloads.map((download, key) => {
                            return <Paper sx={{ padding: '3%', marginBottom: '4%' }} key={key}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <h3>{download.name}</h3>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="outlined" onClick={() => handleDelete(download.id!)}>Löschen</Button>
                                </Box>
                            </Paper>
                        })
                    ) : (null)
                )
            }
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClickOpen} variant="contained">Neuen Download anlegen</Button>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="closeDownload"
            >
                <DialogTitle>{"Neuen Download anlegen"}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center', flexWrap: 'wrap' }}>
                        <TextField
                            placeholder="Name"
                            value={name}
                            onChange={(newValue) => {
                                setName(newValue.target.value)
                            }}
                            sx={{ margin: '20px' }}
                        >
                        </TextField>
                        <TextField
                            placeholder="Passwort"
                            type="password"
                            value={password}
                            onChange={(newValue) => {
                                setPassword(newValue.target.value)
                            }}
                            sx={{ margin: '20px' }}
                        >
                        </TextField>
                        <Form style={{ margin: '20px' }}>
                            <Form.Group>
                                <TextField type="file" id="exampleFormControlFile1" onChange={getFileContext} />
                            </Form.Group>
                            <div style={{ display: showProgress ? "block" : "none", marginTop: '20px', width: '100%' }}>
                                {progressInstance}
                            </div>
                            <div style={{ display: progress===100 ? "block": "none"}}>Rendere Daten.... das kann einige Zeit dauern!</div>
                        </Form>
                        
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button onClick={() => fileUpload(counter)}>Hochladen</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
