import { Alert, Box, Button, Container, CssBaseline, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext"

export default function ForgotPassword() {
  const auth  = useAuth()
  const navigate = useNavigate();

  const [error,setError] = useState<string>("")

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    auth.resetPassword(data.get("email") as string).then(user => {
      navigate('/login')
    }).catch(error => {
      switch(error.code){
        case 'auth/invalid-email':
            setError("Für diese E-Mailadresse existiert kein Konto");
            break;
      }
    })
    
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Geben sie ihre E-Mail-Adresse an um ihr Passwort zurückzusetzen
          </Typography>
          {
            error.length>0 ? (
              <Alert severity="error">{error}</Alert>
            ) : (null)
          }
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Adresse"
              name="email"
              autoComplete="email"
              autoFocus
            />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Passwort zurücksetzten
              </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
