import { toast } from "react-toastify";
import { NikolausBookingFormState, NikolausChild } from "../models";

export function validateBooking(nikolausaktionBooking: NikolausBookingFormState, children: NikolausChild[]): boolean {

    if(nikolausaktionBooking.firstName !== null && nikolausaktionBooking.firstName !== undefined &&
        nikolausaktionBooking.lastName !== null && nikolausaktionBooking.lastName !== undefined &&
        nikolausaktionBooking.street !== null && nikolausaktionBooking.street !== undefined &&
        nikolausaktionBooking.houseNumber !== null && nikolausaktionBooking.houseNumber !== undefined &&
        nikolausaktionBooking.plz !== null && nikolausaktionBooking.plz !== undefined &&
        nikolausaktionBooking.city !== null && nikolausaktionBooking.city !== undefined &&
        nikolausaktionBooking.phoneNumber !== null && nikolausaktionBooking.phoneNumber !== undefined &&
        nikolausaktionBooking.date !== null && nikolausaktionBooking.date !== undefined &&
        nikolausaktionBooking.time !== null && nikolausaktionBooking.time !== undefined 
        )
        {
            for (let index = 0; index < children.length; index++) {
                if(children[index].name!== null && children[index].name !== undefined &&
                    children[index].age!== null && children[index].age !== undefined &&
                    children[index].wellDone !== null && children[index].wellDone !== undefined &&
                    children[index].toImprove !== null && children[index].toImprove !== undefined
                    ){
                        if(children[index].wellDone.length>0 ){

                        }else{
                            toast.warn("Ein Kind muss mindestens einen guten Punkt vorweisen")
                            return false;
                        }

                }else{
                    toast.warn("Bei einem Kind fehlen Angaben")
                    return false;;
                }
                
            }
            return true
        }
        toast.warn("Beim Elternteil fehlen Angaben")
        return false;
}