import { CircularProgress, Container } from "@mui/material"
import React, { FC, useEffect, useRef, useState } from "react"
import { Download } from "../componets/Download/Download"
import { ErrorNotification } from "../configuration/notifications"
import { useDownload } from "../context/DownloadContext"
import { auth } from "../configuration/firebase"
import { DownloadDTO } from "../models"

export const Downloads: FC = () => {

    const downloadContext = useDownload();

    const [downloads, setDownloads] = useState<DownloadDTO[]>([])
    const [url, setUrl] = useState<string>("")

    const ref = useRef<HTMLAnchorElement | null>();

    useEffect(() => {
        downloadContext.getDownloads().then(response => {
            setDownloads(response)
        }).catch(reason => {
            ErrorNotification(reason.statusCode, reason.message)
        }).finally(() => {
            downloadContext.setLoading(false)
        })
    }, [])

    const downloadFile = (id: string, password: string, fileName: string) => {
        downloadContext.autenticateDownload(id, password).then(result => {
            if (result !== undefined) {
                   downloadContext.download(id,result).then(response => {
                        window.open(response,'_blank')
                   }).catch(() => {
                    ErrorNotification(403, "Beim Herunterladen ist es zu einen Fehler gekommen")
                   })
                   
            }else{
                ErrorNotification(403,'Das Password konnte nicht validiert werden!')
            }
        }).catch(reason => {
            ErrorNotification(reason.statusCode, reason.message)
        }).finally(() => {
            downloadContext.setLoading(false)
        })
    }

    return (
        <>
            <br></br>
            <br></br>
            <Container>
                <h1 style={{ textAlign: 'center' }}>Downloads</h1>
                {
                    downloadContext.loading == true ? (<CircularProgress />) : (
                        <Container sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            {
                                downloads.length > 0 ? (
                                    downloads.map((download, key) => {
                                        return <Download key={key} download={download} handleDownload={downloadFile}></Download>
                                    })
                                ) : (<h4>Keine Downloads verfügbar</h4>)
                            }
                        </Container>
                    )
                }
            </Container>
        </>
    )
}