import { stringLength } from "@firebase/util";
import { isMappedTypeNode } from "typescript";
import axiosInstance from "../configuration/axios"
import { ErrorNotification } from "../configuration/notifications";
import { Meeting, NikolausaktionDTO } from "../models"


function mapToObject(aMap: any) {
    let obj = Object.create(null);
    for (let [k, v] of aMap) {
        // We don’t escape the key '__proto__' which can cause problems on older engines
        if (v instanceof Map<string, Meeting[]>) {
            obj[k.toString()] = mapToObject(v); // handle Maps that have Maps as values
        } else {
            obj[k.toString()] = v;              // calling toString handles case where map key is not a string JSON requires key to be a string
        }
    }
    return obj;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
// from http://www.dyn-web.com/tutorials/php-js/json/filter.php
// example replacer function
function replacer(name: any, val: any) {
    if (val instanceof Map) {
        // Convert Map to Object
        return mapToObject(val);
    } else {
        return val; // return as is
    }
}

function reviver(key: any, value: any) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

export const getNikolausaktion = async (): Promise<NikolausaktionDTO> => {
    return await axiosInstance.get('Nikolausaktion/GetNikolausaktion')
        .then(response => {
            return response.data as NikolausaktionDTO;
        }).catch(reason => {
            if(reason.response !==undefined){
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            }else{
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const updateNikolausaktion = async (nikolausAktion: NikolausaktionDTO): Promise<NikolausaktionDTO> => {
    return await axiosInstance.put<NikolausaktionDTO>('Nikolausaktion/UpdateNikolausaktion', nikolausAktion)
        .then(response => {
            return response.data as NikolausaktionDTO;
        }).catch(reason => {
            if(reason.response !==undefined){
                ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
            }else{
                ErrorNotification(undefined, reason.message)
            }
            throw reason;
        })
}

export const createNikolausaktion = async (nikolausAktion: NikolausaktionDTO): Promise<NikolausaktionDTO> => {
    return await axiosInstance.post('Nikolausaktion/CreateNikolausaktion', JSON.stringify(nikolausAktion, replacer) as string, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.data as NikolausaktionDTO;
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}


export const deleteNikolausaktion = async (): Promise<void> => {
     await axiosInstance.delete('Nikolausaktion/DeleteNikolausaktion')
     .catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })  
}

export const getAvailableMeetings = async (): Promise<Meeting[]> => {
    return await axiosInstance.get('Nikolausaktion/GetAvailableMeetings')
    .then(response => {
        return response.data as Meeting[];
    }).catch(reason => {
        if(reason.response !==undefined){
            ErrorNotification(reason.response.data.statusCode, reason.response.data.message)
        }else{
            ErrorNotification(undefined, reason.message)
        }
        throw reason;
    })
}