import {
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import Footer from "../componets/Footer";
import { useEvent } from "../context/EventContext";
import { EventDTO } from "../models";
import DownloadIcon from "@mui/icons-material/Download";
import { ErrorNotification } from "../configuration/notifications";

export default function Events() {
  const eventContext = useEvent();
  const [events, setEvents] = useState<EventDTO[]>([]);

  function getTime(date?: Date) {
    return date !== undefined ? new Date(date).getTime() : 0;
  }
  useEffect(() => {
    eventContext.getEvents().then((response) => {
      setEvents(response);
    }).catch(reason => {
      ErrorNotification(reason.statusCode,reason.message);
    }).finally(() => {
      eventContext.setLoading(false)
    });
  }, []);

  const downloadICSFile = () => {
    eventContext.downloadICSFile().then(result => {
      const url = window.URL.createObjectURL(new Blob([result]))
      const link = document.createElement('a')
      link.href = url;
      link.target = "_blank"
      link.setAttribute('download', "termine.ics")
      document.body.appendChild(link)
      link.click();
      link.parentNode?.removeChild(link)
    }).finally(() => {
      eventContext.setLoading(false)
    })
  }

  return (
    <div>
      <div>
        <br></br>
        <br></br>
        <br></br>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1>Termine in diesem Jahr</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              Über das ganze Jahr führt die Pfarrjugend Neusäß verschiedenene
              Aktionen durch! Hier sind die Termine für dieses Jahr!
            </p>
            <Tooltip title="Als ICS-Datei herunterladen">
              <IconButton onClick={() => downloadICSFile()}>
                <DownloadIcon color="primary"></DownloadIcon>
              </IconButton>
            </Tooltip>
          </div>
          {
            eventContext.loading === true ? (<CircularProgress />) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Datum</TableCell>
                      <TableCell align="center">Aktion</TableCell>
                      <TableCell align="center">Ort</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      events
                        .sort((a, b) => {
                          return getTime(a.startDate) - getTime(b.startDate);
                        })
                        .map((event, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell align="center">
                                {new Date(event.startDate!).toLocaleDateString("de-DE", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })} {
                                  new Date(event.startDate!).toLocaleTimeString("de-DE", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                  })
                                } Uhr -  {new Date(event.endDate!).toLocaleDateString("de-DE", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })} {
                                  new Date(event.endDate!).toLocaleTimeString("de-DE", {
                                    hour: "2-digit",
                                    minute: "2-digit"
                                  })
                                } Uhr
                              </TableCell>
                              <TableCell align="center">{event.name}</TableCell>
                              <TableCell align="center">{event.location}</TableCell>
                            </TableRow>
                          );
                        })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }
        </Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
      <Footer></Footer>
    </div>
  );
}
