import { Switch, Grid, TextField, Container, TableBody, TableCell, TableContainer, TableHead, TableRow, Slide, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, CircularProgress, Table, Button } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, useEffect } from "react"
import { SuccessNotification } from "../../configuration/notifications";
import { useZeltlager } from "../../context/ZeltlagerContext";
import { ZeltlagerBookingDTO, ZeltlagerDTO } from "../../models";
import TextSplitter from "../TextSplitter";
import { ZeltlagerBookings } from "../Zeltlager/ZeltlagerBookings";
import { useZeltlagerBooking } from "../../context/ZeltlagerBookingContext";




const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ZeltlagerAdmin: FC = () => {
    const [valueStartDate, setValueStartDate] = React.useState<Dayjs>(dayjs(Date.now()));
    const [valueEndDate, setValueEndDate] = React.useState<Dayjs>(dayjs(Date.now()));

    const [active, setActive] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    const zeltlagerContext = useZeltlager();
    const zeltlagerBookingContext = useZeltlagerBooking();

    const [zeltlager, setZeltlager] = React.useState<ZeltlagerDTO>({});
    const [newZeltlager, setNewZeltlager] = React.useState<ZeltlagerDTO>({ startDate: new Date(), endDate: new Date()});

    const [allBookings, setAllBookings] = React.useState<ZeltlagerBookingDTO[]>([]);



    useEffect(() => {
        zeltlagerContext.getZeltlager().then(result => {
            if (result !== undefined && result !== null) {
                setZeltlager(result)
                setActive(result.active!)
            }
        }).finally(() => {
            zeltlagerContext.setLoading(false)
        })
        ;
    }, [])



    const handleClickOpenDelete = () => {
        setOpen(true);
    };
    const handleCloseDelete = () => {
        setOpen(false);
    };
    const handleChangeStartDate = (newValue: Dayjs) => {
        setValueStartDate(newValue);
    };
    const handleChangeEndDate = (newValue: Dayjs) => {
        setValueEndDate(newValue);
    };


    const handleDelete = (zeltlagerDataId: string) => {
        zeltlagerContext.deleteZeltlager(zeltlagerDataId).then(result => {
            setZeltlager({});
            setActive(false)
            handleCloseDelete();
            SuccessNotification('Das Zeltlager wurde erfolgreich gelöscht!')
        }).finally(() => {
            zeltlagerContext.setLoading(false)
        })
    }


    const addZeltlager = () => {
        newZeltlager.active = active
        zeltlagerContext.createZeltlager(newZeltlager).then(result => {
            setZeltlager(result)
            setActive(result.active!)
            setNewZeltlager({})
            SuccessNotification('Das Zeltlager wurde erfolgreich hinzugefügt!')
        }).finally(() => {
            zeltlagerContext.setLoading(false)
        })
    }

    const SendReminderEmail = () => {
        if(window.confirm("Soll die Remindermail wirklich geschickt werden?")){
            zeltlagerContext.sendReminderEamil().then(result => {
                SuccessNotification('Die Reminder Email wurde verschickt')
            })
        }
    }

    const updateZeltlager = () => {
        zeltlager.active = active
        zeltlagerContext.updateZeltlager(zeltlager).then(result => {
            setZeltlager(result)
            setActive(result.active!)
            SuccessNotification('Das Zeltlager wurde erfolgreich aktualisiert!')
        }).finally(() => {
            zeltlagerContext.setLoading(false)
        })
    }

    const loadAllBookings = () => {
        zeltlagerBookingContext.getAllZeltlagerBookings()
        .then((result) => {
          setAllBookings(result);
        })
        .finally(() => {
          zeltlagerBookingContext.setLoading(false);
        });
      }

    return (
        <>
            {
                zeltlagerContext.loading === true ? (
                    <CircularProgress></CircularProgress>
               ) : (
                <Container>

                <Container sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Switch type={"checkbox"} checked={active} onChange={(value) => setActive(value.target.checked)} />
                    <h3 style={{ margin: 0 }}>Zeltlager aktiv</h3>
                    <br></br>
                    <Button style={{ marginLeft: '6%' }} variant="contained" onClick={() => updateZeltlager()}>Aktualisieren</Button>
                </Container>

                <Container sx={{ marginBottom: '2%' }}>
                    {
                        zeltlager?.id !== null && zeltlager?.id !== undefined ? (
                            <>
                            <TextSplitter />
                            <h3>Übersicht Daten</h3>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Wann?</TableCell>
                                            <TableCell align="center">Wo?</TableCell>
                                            <TableCell align="center">Preis (in Euro)</TableCell>
                                            <TableCell align='center'></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            zeltlager !== undefined && zeltlager !== null && zeltlager?.startDate !== undefined && zeltlager?.startDate !== null &&
                                                zeltlager?.endDate !== null && zeltlager?.endDate !== undefined ? (<TableRow>
                                                    <TableCell align="center">
                                                        {new Date(zeltlager.startDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })} - {new Date(zeltlager.endDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}
                                                    </TableCell>
                                                    <TableCell align="center"> {zeltlager.location}
                                                    </TableCell>
                                                    <TableCell align="center">{zeltlager.price}</TableCell>
                                                    <TableCell align="center"><Grid>
                                                        <Button
                                                            onClick={handleClickOpenDelete}
                                                            variant="contained"
                                                        >
                                                            Löschen
                                                        </Button>

                                                        <Dialog
                                                            open={open}
                                                            TransitionComponent={Transition}
                                                            keepMounted
                                                            onClose={handleCloseDelete}
                                                            aria-describedby="deleteZeltlagerData"
                                                        >
                                                            <DialogTitle>{"Zeltlager dauerhaft löschen?"}</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="deleteZeltlagerData">
                                                                    Zeltlager wirklich löschen?
                                                                    Denk nochmal nach
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleCloseDelete}>Nein</Button>
                                                                <Button onClick={() => handleDelete(zeltlager.id!)}>Ja</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </Grid></TableCell>
                                                </TableRow>) : (null)
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>  
                        ) : (null)
                    }

                    {
                        zeltlager?.id !== null && zeltlager?.id !== undefined ? (null) : (
                            <>
                                <TextSplitter />
                                <h2>Termin hinzufügen</h2>
                                <Grid xs={12} sm={4} marginBottom={'3%'}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Start-Tag"
                                            inputFormat="DD.MM.YYYY"
                                            minDate={dayjs()}
                                            value={valueStartDate}
                                            onChange={(newValue) => {
                                                setNewZeltlager({ ...newZeltlager, startDate: newValue?.toDate() })
                                                handleChangeStartDate(newValue as Dayjs)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="End-Tag"
                                            inputFormat="DD.MM.YYYY"
                                            value={valueEndDate}
                                            onChange={(newValue) => {
                                                setNewZeltlager({ ...newZeltlager, endDate: newValue?.toDate() })
                                                handleChangeEndDate(newValue as Dayjs)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>

                                </Grid>
                                <Grid item xs={12} sm={11} marginBottom={'3%'}>
                                    <TextField
                                        required
                                        label="Standort"
                                        id="standort"
                                        fullWidth
                                        onChange={(newText) => {
                                            setNewZeltlager({ ...newZeltlager, location: newText.target.value })
                                        }}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={11} marginBottom={'3%'}>
                                    <TextField
                                        required
                                        label="Standort Maps Link"
                                        id="standort-maps-link"
                                        fullWidth
                                        onChange={(newText) => {
                                            setNewZeltlager({ ...newZeltlager, locationLink: newText.target.value })
                                        }}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={3} marginBottom={'3%'}>
                                    <TextField
                                        required
                                        label="Preis in Euro"
                                        name="quantitiy"
                                        type="number"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                        id="preis"
                                        sx={{marginRight: '1em'}}
                                        onChange={(newText) => {
                                            setNewZeltlager({ ...newZeltlager, price: Number.parseInt(newText.target.value) })
                                        }}
                                    ></TextField>
                                    <TextField
                                        required
                                        label="Limit Personen"
                                        name="Limit"
                                        type="number"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
                                        id="preis"
                                        onChange={(newText) => {
                                            setNewZeltlager({ ...newZeltlager, limit: Number.parseInt(newText.target.value) })
                                        }}
                                    ></TextField>
                                </Grid>
                                
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained" onClick={() => addZeltlager()}>
                                        Hinzufügen
                                    </Button>

                                </Grid>
                            </>
                        )
                    }

                </Container>                
                <ZeltlagerBookings bookings={allBookings}></ZeltlagerBookings>
                <Button color="primary" variant="contained" onClick={loadAllBookings}>Neuladen</Button>
                <Container style={{ marginTop: '100px'}}>
                    <Button variant="contained" onClick={() => SendReminderEmail()}>
                        Reminder Email schicken
                    </Button>
                </Container>
            </Container>
                )
            }

        </>
    )
}