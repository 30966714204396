import React, { FC, ReactNode, useState } from "react"
import { Dispatch, SetStateAction } from "react"
import { SpielewochenendeDTO } from "../models"
import * as service from "../services/SpielewochenendeService"

interface SpielewochenendeContextInterface {
    loading: boolean,
    getSpielewochenende: () => Promise<SpielewochenendeDTO>
    createSpielewochenende: (spielewochenende: SpielewochenendeDTO) => Promise<SpielewochenendeDTO>
    deleteSpielewochenende: (spielewochenendeId: string) => Promise<void>
    updateSpielewochenende: (spielewochenenede: SpielewochenendeDTO) => Promise<SpielewochenendeDTO>
    downloadBookingsAsPDF: () => Promise<Blob>
    setLoading: Dispatch<SetStateAction<boolean>>
}

const Context = React.createContext<SpielewochenendeContextInterface>({
    loading: false,
    getSpielewochenende: () => new Promise<SpielewochenendeDTO>(() => {}),
    createSpielewochenende: (spielewochenende: SpielewochenendeDTO) => new Promise<SpielewochenendeDTO>(() => {}),
    deleteSpielewochenende: (sielewochenendeId: string) => new Promise<void>(() => {}),
    updateSpielewochenende: (spielewochenende: SpielewochenendeDTO) => new Promise<SpielewochenendeDTO>(() => {}),
    downloadBookingsAsPDF: () => new Promise<Blob>(() => {}),
    setLoading: () => {}
})

 const SpielewochenendeContext : FC<{children: ReactNode}> = (props) => {
    const [loading, setLoading] = useState(true)

    const getSpielewochenende =async (): Promise<SpielewochenendeDTO> => {
        setLoading(true)
        const response = await service.getSpielewochenende();
        return response as SpielewochenendeDTO
    }

    const createSpielewochenende =async (spielewochenende:	SpielewochenendeDTO): Promise<SpielewochenendeDTO> => {
        setLoading(true)
        const response = await service.createSpielewochenende(spielewochenende)
        return response as SpielewochenendeDTO
    }

    const deleteSpielewochenende = async (): Promise<void> => {
        setLoading(true)
        const response = await service.deleteSpielewochenende()
        return response
    }

    const updateSpielewochenende =async (spielewochenende:SpielewochenendeDTO) => {
        const response = await service.updateSpielewochenende(spielewochenende)
        return response as SpielewochenendeDTO
    }

    const downloadBookingsAsPDF = async (): Promise<Blob> => {
        setLoading(true)
        const response = await service.downloadBookingsAsPDF()
        return response as Blob
    }

    return (
        <Context.Provider
        value={{
            loading,
            setLoading, 
            getSpielewochenende,
            createSpielewochenende,
            deleteSpielewochenende,
            updateSpielewochenende,
            downloadBookingsAsPDF
        }}>
            {props.children}
        </Context.Provider>
    )
 }
 export default SpielewochenendeContext
 export const useSpielewochenende = () => React.useContext(Context)