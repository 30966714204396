import { Grid, TextField, Checkbox, Box, Container, Button } from "@mui/material"
import { Dispatch, FC,  SetStateAction, useState } from "react"
import Datenschutz from "../../componets/Anmeldung/Datenschutz"
import TextSplitter from "../../componets/TextSplitter"
import { AktionsChild } from "../../componets/Zeltlager/AktionsChild"
import { SuccessNotification } from "../../configuration/notifications"
import { useAuth } from "../../context/AuthContext"
import { useZeltlagerBooking } from "../../context/ZeltlagerBookingContext"
import { getZeltlagerBookingDTO, getZeltlagerBookingFormState } from "../../mapper/ZeltlagerBookingMaper"
import { AktionChildDTO, ZeltlagerBookingFormState } from "../../models"
import { validateBookingZeltlager } from "../../validator/ZeltlagerBookingValidator"

interface Props {
    newBooking: ZeltlagerBookingFormState
    setNewBooking: Dispatch<SetStateAction<ZeltlagerBookingFormState>>
    children: AktionChildDTO[]
    checkBoxCarCapacityChecked: boolean
    setCheckBoxCarCapacityChecked: Dispatch<SetStateAction<boolean>>
    checkBoxRegistryChecked: boolean
    setCheckBoxRegistryChecked: Dispatch<SetStateAction<boolean>>
    setChildren: Dispatch<SetStateAction<AktionChildDTO[]>>
    addChild: () => void
    registerChildren: () => void
}

export const ZeltlagerAnmeldung: FC<Props> = (props) => {
    const auth = useAuth();



    return (
        <>
            <Container>
                <h2 style={{ textAlign: 'center' }}>Anmeldung</h2>
                <br></br>
                <h4>Elternteil</h4>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="firstName"
                            label="Vorname"
                            fullWidth
                            autoComplete="given-name"
                            variant="outlined"
                            onChange={(newText) => {
                                props.setNewBooking({ ...props.newBooking, parent: { ...props.newBooking?.parent, firstName: newText.target.value } })
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="lastname"
                            label="Nachname"
                            fullWidth
                            autoComplete="family-name"
                            variant="outlined"
                            onChange={(newText) => {
                                props.setNewBooking({ ...props.newBooking, parent: { ...props.newBooking?.parent, lastName: newText.target.value } })
                            }}
                        ></TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="phoneNumber"
                            label="Telefonnumer"
                            type="number"
                            fullWidth
                            autoComplete="phonenumber"
                            variant="outlined"
                            onChange={(newText) => {
                                props.setNewBooking({ ...props.newBooking, parent: { ...props.newBooking?.parent, phoneNumber: newText.target.value } })
                            }}
                        ></TextField>
                    </Grid>

                    <Grid item xs={12} sm={2.5} marginTop={1}>
                        <Checkbox checked={props.checkBoxCarCapacityChecked} onChange={(value) => props.setCheckBoxCarCapacityChecked(value.target.checked)} />
                        Ich kann fahren
                    </Grid>
                    <Grid item xs={12} sm={3.5}>
                        <TextField
                            id="carCapacity"
                            label="Auto Kapazität"
                            type="number"
                            fullWidth
                            autoComplete="carcapacity"
                            variant="outlined"
                            disabled={!props.checkBoxCarCapacityChecked}
                            onChange={(newText) => {
                                props.setNewBooking({ ...props.newBooking, parent: { ...props.newBooking?.parent, carCapacity: Number.parseInt(newText.target.value) } })
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            required
                            id="street"
                            label="Straße"
                            fullWidth
                            autoComplete="street"
                            variant="outlined"
                            onChange={(value) => {
                                props.setNewBooking({ ...props.newBooking, address: { ...props.newBooking?.address, street: value.target.value } })
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            id="houseNumber"
                            label="Hausnummer"
                            fullWidth
                            autoComplete="houseNumber"
                            variant="outlined"
                            onChange={(value) => {
                                props.setNewBooking({ ...props.newBooking, address: { ...props.newBooking?.address, houseNumber: value.target.value } })
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            sx={{
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                }
                            }}
                            required
                            id="plz"
                            label="Postleitzahl"
                            fullWidth
                            type="number"
                            autoComplete="plz"
                            variant="outlined"
                            onChange={(value) => {
                                props.setNewBooking({ ...props.newBooking, address: { ...props.newBooking?.address, plz: Number.parseInt(value.target.value) } })
                            }}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="city"
                            label="Stadt"
                            fullWidth
                            autoComplete="city"
                            variant="outlined"
                            onChange={(value) => {
                                props.setNewBooking({ ...props.newBooking, address: { ...props.newBooking?.address, city: value.target.value } })
                            }}
                        ></TextField>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                    <Grid >
                        {

                            props.children?.map((child, keyOfChild) => {
                                return <AktionsChild child={child} children={props.children} keyOfChild={keyOfChild} setChildren={props.setChildren} disableButtons={false}></AktionsChild>
                            })}

                    </Grid>
                </Grid>
                <br />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={props.addChild}>Kind hinzufügen</Button></Box>
                <TextSplitter />
                <Datenschutz checked={props.checkBoxRegistryChecked} setCheckBox={props.setCheckBoxRegistryChecked} routing={false} />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" disabled={!props.checkBoxRegistryChecked} onClick={props.registerChildren}>Kind/Kinder anmelden</Button></Box>
            </Container>
        </>
    )
}