import { Box, Container, Paper, Typography, useMediaQuery } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router';
import LaunchIcon from '@mui/icons-material/Launch';



export default function Footer() {

  const navigate = useNavigate();
  const mediaquery = useMediaQuery('(max-width: 500px)')

  return (
    <Paper sx={{
      marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      color: 'secondardy',
      bottom: 0,
      backgroundColor: 'black',
      display: 'flex',
      flexWrap: 'wrap'
    }} component="footer" square variant="outlined">
      <Container maxWidth="lg" sx={{
        display: 'flex'
      }}>
        <Box
          sx={{
            flexGrow: 1,
            flexDirection: mediaquery ? ('column') : ('row'),
            justifyContent: mediaquery ? ('space-evenly') : ('space-between'),
            alignItems: mediaquery ? ('center') : ('space-around'),
            display: "flex",
            my: 2
          }}
        >
          <Typography variant="caption" sx={{ marginBottom: mediaquery ? ('10px') : ('0') }}>
            <a href="https://www.instagram.com/pfarrjugend_neusaess/" target="_blank" rel="noreferrer" title='Dieser Link führt zu Instagram'>
              <InstagramIcon style={{ color: '#00c20c' }}></InstagramIcon>
              <LaunchIcon style={{ color: '#00c20c', fontSize: 'small', marginRight: '20px', marginTop: '7px' }} ></LaunchIcon>
            </a>
            <a href="mailto:website@pfarrjugendneusaess.de">
              <EmailIcon style={{ color: '#00c20c' }} sx={{ marginLeft: '20px' }}></EmailIcon>

            </a>
          </Typography>
          <Typography variant="caption" color="white" sx={{ cursor: 'pointer', marginBottom: mediaquery ? ('10px') : ('0') }} onClick={() => navigate('/')}>
            Pfarrjugend Neusäß
          </Typography>
          <Typography variant="caption" color="white" sx={{ marginBottom: mediaquery ? ('10px') : ('0'), cursor: 'pointer' }} onClick={() => navigate('/impressum')}>
            Impressum / Datenschutz
          </Typography>
          <Typography variant="caption" color="white" sx={{ marginBottom: mediaquery ? ('10px') : ('0') }}>
            Copyright ©2023
          </Typography>
        </Box>
      </Container>
    </Paper>
  )
}
