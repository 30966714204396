import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, Tooltip,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { Children, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useZeltlagerBooking } from "../../context/ZeltlagerBookingContext";
import { useZeltlager } from "../../context/ZeltlagerContext";
import {
  AddressDTO,
  AktionChildDTO,
  ParentDTO,
  ZeltlagerBookingDTO,
  ZeltlagerDTO,
} from "../../models";
import TextSplitter from "../TextSplitter";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props{
  bookings: ZeltlagerBookingDTO[]
}

export const ZeltlagerBookings: FC<Props> = (props) => {
  const [allBookings, setAllBookings] = useState<ZeltlagerBookingDTO[]>();
  const ZeltlagerBookingContext = useZeltlagerBooking();
  const [openMail, setOpenMail] = React.useState(false);
  const [openOtherRemarks, setOpenOtherRemarks] = React.useState(false);
  const [emailText, setEmailText] = useState<string>("");
  const [otherRemarksChild, setOtherRemarksChild] = useState<AktionChildDTO>();
  const [parent, setParent] = useState<ParentDTO>();
  const [address, setAdress] = useState<AddressDTO>();
  const [bookingToDeleteId, setBookingToDeleteId] = useState<string>("");
  const [childToDeleteId, setChildToDeleteId] = useState<string>("");
  const { downloadBookingsAsPDF, setLoading } = useZeltlager();

  const handleClickOpenMail = (bookingId: string, childId: string) => {
    setOpenMail(true);
    setBookingToDeleteId(bookingId);
    setChildToDeleteId(childId);
  };
  const downloadBookings = () => {
    downloadBookingsAsPDF().then((result) => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", "Zeltlager.zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }).finally(() => {
      setLoading(false);
    });;
  };

  const handleClickOpenOtherRemarks = (
    child: AktionChildDTO,
    parent: ParentDTO,
    address: AddressDTO
  ) => {
    setOpenOtherRemarks(true);
    setOtherRemarksChild(child);
    setParent(parent);
    setAdress(address);
  };
  const handleCloseOtherRemarks = () => {
    setOpenOtherRemarks(false);
    setOtherRemarksChild({});
    setParent({});
    setAdress({});
  };
  const handleCloseMail = () => {
    setOpenMail(false);
    setEmailText("");
    setBookingToDeleteId("");
    setChildToDeleteId("");
  };

  const sendCancelEmail = () => {
    ZeltlagerBookingContext.deleteZeltlagerBookingAsAdmin(
      bookingToDeleteId,
      childToDeleteId,
      emailText
    )
      .then(() => {
        ZeltlagerBookingContext.getAllZeltlagerBookings().then((result) => {
          setAllBookings(result);
        });
        handleCloseMail();
      })
      .finally(() => {
        ZeltlagerBookingContext.setLoading(false);
      });
  };

  let sequenceNumber = 1;

    const GetSequenceNumber = () => {
        let result = sequenceNumber;
        sequenceNumber++;
        return result;
    }

  return props.bookings.length > 0 ? (
    <>
      <TextSplitter />
      <Tooltip title="Anmeldungen als PDF herunterladen">
        <IconButton onClick={() => downloadBookings()}>
          <DownloadIcon color="primary"></DownloadIcon>
        </IconButton>
      </Tooltip>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Anzahl</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Geschlecht</TableCell>
              <TableCell align="center">Geburtstag</TableCell>
              <TableCell align="center">Sontiges/ Daten der Eltern</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.bookings.length > 0
              ? props.bookings.map((booking, key) => {
                return booking.children?.map((child, index) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      key={key + index}
                    >
                      <TableCell>{GetSequenceNumber()}</TableCell>
                      <TableCell align="center">
                        {child.firstName + " " + child.lastName}
                      </TableCell>
                      <TableCell align="center">{child.gender}</TableCell>
                      <TableCell align="center">{child.birthday}</TableCell>
                      <TableCell align="center">
                        <Button 
                          onClick={() =>
                            handleClickOpenOtherRemarks(
                              child,
                              booking.parent!,
                              booking.address!
                            )
                          }
                          variant="contained"
                        >
                          Sonstige Daten
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleClickOpenMail(booking.id!, child.id!)
                          }
                          variant="contained"
                        >
                          Stornieren
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                });
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openOtherRemarks}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseOtherRemarks}
        aria-describedby="otherRemarks"
        fullWidth
      >
        <DialogTitle>
          {"Sonstiges von " +
            otherRemarksChild?.firstName +
            " " +
            otherRemarksChild?.lastName}
        </DialogTitle>
        <DialogContent>
          <h3>
            <b>Sonstiges</b>
          </h3>
          <p>{otherRemarksChild?.otherRemarks}</p>
          <TextSplitter />
          <h3>
            <b>Medikamente</b>
          </h3>
          <p>{otherRemarksChild?.medics}</p>
          <TextSplitter />
          <h3>
            <b>Allergien</b>
          </h3>
          <p>{otherRemarksChild?.allergica}</p>
          <TextSplitter />
          <h3>
            <b>Elternteil</b>
          </h3>
          <p>
            <b>Name: </b>
            {parent?.firstName + " " + parent?.lastName}
          </p>
          <p>
            <b>Telefonnummer: </b>
            {parent?.phoneNumber}
          </p>
          <p>
            <b>Adresse: </b>
            {address?.street +
              " " +
              address?.houseNumber +
              ", " +
              address?.plz +
              " " +
              address?.city}
          </p>
          <p>
            <b>Auto-Kapaziät: </b>
            {parent?.carCapacity}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtherRemarks}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMail}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseMail}
        aria-describedby="stornoMail"
        fullWidth
      >
        <DialogTitle>Anmeldung Stornieren</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="stornoMail"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Schreibe einen Text für die Stornier-E-Mail"
              style={{ width: "100%" }}
              value={emailText}
              onChange={(value) => {
                setEmailText(value.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMail}>Abbrechen</Button>
          <Button onClick={() => sendCancelEmail()}>Senden</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};
