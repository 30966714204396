import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { CreateDownloadDTO, DownloadDTO } from "../models";
import * as service from '../services/DownloadService'


interface EventContextInterface {
    loading: boolean,
    getDownloads: () => Promise<DownloadDTO[]>
    download: (id: string, token: string) => Promise<string>
    deleteDownload: (id: string) => Promise<void>
    uploadChunk: (chunk: any, counter: number, fileGuid: any) => any
    uploadCompleted: (fileName: any, formData: FormData) => any
    setLoading: Dispatch<SetStateAction<boolean>>,
    autenticateDownload: (id: string, password: string) => Promise<string>
  }
  
  
  const Context = React.createContext<EventContextInterface>({
    loading: false,
    getDownloads: () => new Promise(() =>  {}),
    download: () => new Promise(() => {}),
    deleteDownload: () => new Promise(() => {}),
    uploadChunk: (chunk: any, counter: number, fileGuid: any) => {},
    uploadCompleted: (fileName: any, formData: FormData) => {},
    setLoading: () => {},
    autenticateDownload: (id: string, password: string) => new Promise(() => {})
  });

const DownloadContext: FC<{ children: ReactNode }> = (props) => {
    
  const [loading, setLoading] = useState(true)

    const getDownloads = async (): Promise<DownloadDTO[]> => {
        setLoading(true)
        const response = await service.getDownloads();
        return response as DownloadDTO[]
    }

    const download = async (id: string, token: string): Promise<string> => {
        setLoading(true)
        const response = service.download(id,token)
        return response;
    }

    const autenticateDownload = async (id: string, password: string): Promise<string> => {
        setLoading(true)
        const response = await service.authenticateDownload(id,password)
        return response.data as string;
    }
    const deleteDownload = async (id: string): Promise<void> => {
        setLoading(true)
        const response = await service.deleteDownload(id)
        return response;
    }

    const uploadChunk = async (chunk: any, counter: number, fileGuid: any) => {
        setLoading(true)
        return service.uploadChunk(chunk,counter,fileGuid)
    }

    const uploadCompleted = async (chunk: any, formData: FormData) => {
      setLoading(true)
      return service.uploadComplete(chunk,formData)
  }

    
    return (
        <Context.Provider
          value={{
            loading,
            setLoading,
            getDownloads,
            download,
            autenticateDownload,
            deleteDownload,
            uploadChunk,
            uploadCompleted
          }}>
          {props.children}
        </Context.Provider>
      );
}

export default DownloadContext;
  export const useDownload = () => React.useContext(Context)