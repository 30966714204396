import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Slide, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { getValue } from "@mui/system";
import { deleteUser } from "firebase/auth";
import React, { FC, useEffect, useState } from "react";
import ButtonToolbar from "react-bootstrap/esm/ButtonToolbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SuccessNotification } from "../configuration/notifications";
import { useAuth } from "../context/AuthContext";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const User: FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [open, setOpen] = useState(false);

    const [firstName,setFirstName] = useState<string>("")
    const [lastName,setLastName] = useState<string>("")
    const [email,setEmail] = useState<string>("")


    useEffect(() => {
        var nameArray = auth.currentUser?.displayName?.split(" ");
        if(nameArray !== undefined && nameArray !==null){
            if(nameArray[0] !== null && nameArray[0] !== undefined){
                setFirstName(nameArray[0])
            }
            if(nameArray[1] !== null && nameArray[1] !== undefined){
                setLastName(nameArray[1])
            }
            setEmail(auth.currentUser?.email!)
        }
    }, [])
    

    const navigateToHome = () => {
        navigate('/')
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

    };
    const handleDelete = () => {
        setOpen(false);
        auth.deleteUserProfile().then(result => {
            navigateToHome();
        });
    }

    const updateProfile = () => {
        if(firstName!==undefined && firstName!==null && lastName !==undefined && lastName!==null){
            auth.updateProfileOfUser(auth.currentUser!, firstName + " " + lastName).then(() => {
                var nameArray = auth.currentUser?.displayName?.split(" ");
            if(nameArray !== undefined && nameArray !==null){
                if(nameArray[0] !== null && nameArray[0] !== undefined){
                    setFirstName(nameArray[0])
                }
                if(nameArray[1] !== null && nameArray[1] !== undefined){
                    setLastName(nameArray[1])
                }
                
            }
            })
        }
        if(email!== null && email!== undefined && email !== auth.currentUser?.email){
            auth.updateEmailAdress(email).then(() => {
                auth.sendVerificationEmail(auth.currentUser!)
                setEmail(auth.currentUser?.email!)
            })
        }
        auth.logout();
        SuccessNotification("Ihr Profil wurde erfolgreich aktualisiert!")
        toast.info("Sie wurde abgemeldet!")
        navigate("/")
    }


    function handleSubmit() {
    }
    function handleLogout() {
        auth.logout();
    }

    return (
        <Container >
            <br></br>
            <br></br>
            <br></br>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Typography component="h1" variant="h3">
                    Benutzerprofil
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                value={firstName}
                                onChange={(value) => setFirstName(value.target.value)}
                                fullWidth
                                id="firstName"
                                label="Vorname"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                value={lastName}
                                onChange={(value) => setLastName(value.target.value)}
                                label="Nachname"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                value={email}
                                onChange={(value) => setEmail(value.target.value)}
                                label="Email Adresse"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={11} spacing={3} marginLeft={2}>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={updateProfile}
                            >
                                Profil aktualsieren
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Link to='/'>
                                <Button
                                    onClick={handleLogout}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Abmelden
                                </Button>
                            </Link>
                        </Grid>

                    </Grid>
                    <Grid container item xs={10.6} spacing={3} marginLeft={2}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={handleClickOpen}
                                sx={{ mt: 1, mb: 2 }}
                                fullWidth
                            >
                                Konto löschen
                            </Button>
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-describedby="deleteUser"
                            >
                                <DialogTitle>{"Konto dauerhaft löschen?"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="deleteUser">
                                        Möchten sie ihr Konto wirklich löschen?
                                        Ihr Account und die dazu gehörigen Daten können nicht wiederhergestellt werden!
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Nein</Button>
                                    <Button onClick={handleDelete}>Ja</Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}