import axios from "axios";
import { auth } from "./firebase";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_URL,
    timeout: 15000,
    responseType: "json"
  });

  axiosInstance.interceptors.request.use(async function (config) {
    const token = await auth.currentUser?.getIdToken(false);
    if (config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

export default axiosInstance;