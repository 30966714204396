import { Button, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import { AktionChildDTO, ZeltlagerBookingFormState, ZeltlagerDTO } from "../models";
import CheckAnmeldung from "../componets/Anmeldung/CheckAnmeldung";
import ZeltlagerTexte from "../componets/Zeltlager/ZeltlagerTexte";
import TextSplitter from "../componets/TextSplitter";
import { useAuth } from "../context/AuthContext";
import { useZeltlager } from "../context/ZeltlagerContext";
import DirectionsIcon from '@mui/icons-material/Directions';
import { getZeltlagerBookingDTO, getZeltlagerBookingFormState } from "../mapper/ZeltlagerBookingMaper";
import { useZeltlagerBooking } from "../context/ZeltlagerBookingContext";
import { validateBookingZeltlager } from "../validator/ZeltlagerBookingValidator";
import { TransitionProps } from "@mui/material/transitions/transition";
import { ErrorNotification, InformationNotification, SuccessNotification } from "../configuration/notifications";
import { UpdateNikolausBooking } from "../componets/Nikolaus/UpdateNikolausBooking";
import { UpdateZeltlagerBooking } from "../componets/Zeltlager/UpdateZeltlagerBooking";
import { ZeltlagerAnmeldung } from "../componets/Zeltlager/ZeltlagerAnmeldung";





export const Zeltlager = () => {

    const auth = useAuth();

    const [zeltlager, setZeltlager] = useState<ZeltlagerDTO>({})
    const [booking, setBooking] = useState<ZeltlagerBookingFormState>({});
    const [newBooking, setNewBooking] = useState<ZeltlagerBookingFormState>({})
    const [bookingActive, setBookingActive] = useState(false)
    const [children, setChildren] = useState<AktionChildDTO[]>([])
    const [checkBoxCarCapacityChecked, setCheckBoxCarCapacityChecked] = useState<boolean>(false)
    const [checkBoxRegistryChecked, setCheckBoxRegistryChecked] = useState<boolean>(false)


    const zeltlagerContext = useZeltlager();
    const zeltlagerBookingContext = useZeltlagerBooking();



    useEffect(() => {
        if (auth.currentUser !== null && auth.currentUser !== undefined) {
            zeltlagerContext.getZeltlager().then(result => {
                if (result !== undefined && result !== null) {
                    setZeltlager(result)
                    setBookingActive(result.active!)
                }
            }).finally(() => {
                zeltlagerBookingContext.setLoading(false);
            });
            zeltlagerBookingContext.getZeltlagerBookingForUser().then(result => {
                let formState = getZeltlagerBookingFormState(result)
                if (formState.id !== undefined) {
                    setBooking(formState)
                    setCheckBoxCarCapacityChecked(formState.parent?.carCapacity! > 0)
                    setChildren(result.children!)
                }
            }).finally(() => {
                zeltlagerBookingContext.setLoading(false);
            })
        }
    }, [])

    const addChild = () => {
        setChildren([...children, {} as AktionChildDTO])
    }

    const reload = () => {
        if (auth.currentUser !== null && auth.currentUser !== undefined) {
            zeltlagerContext.getZeltlager().then(result => {
                if (result !== undefined && result !== null) {
                    setZeltlager(result)
                    setBookingActive(result.active!)
                }
            }).finally(() => {
                zeltlagerBookingContext.setLoading(false);
            });
            zeltlagerBookingContext.getZeltlagerBookingForUser().then(result => {
                let formState = getZeltlagerBookingFormState(result)
                if (formState.id !== undefined) {
                    setBooking(formState)
                    setCheckBoxCarCapacityChecked(formState.parent?.carCapacity! > 0)
                    setChildren(result.children!)
                }
            }).finally(() => {
                zeltlagerBookingContext.setLoading(false);
            })
        }
    }

    const updateBooking = () => {
        if (validateBookingZeltlager(booking, children)) {
            let zeltlagerBooking = getZeltlagerBookingDTO(booking, children)
            zeltlagerBookingContext.updateZeltlagerBooking(zeltlagerBooking).then(result => {
                setBooking(result)
                SuccessNotification("Die Zeltlageranmeldung wurde erfolgreich aktualisiert!")
            }).finally(() => {
                zeltlagerBookingContext.setLoading(false)
            })
        }
    }

    const deleteBooking = () => {
        zeltlagerBookingContext.deleteZeltlagerBooking(booking.id!).then(() => {
            setBooking({})
            setChildren([])
            setCheckBoxCarCapacityChecked(false)
            setCheckBoxRegistryChecked(false)
            SuccessNotification("Die Zeltlageranmeldung wurde erfolgreich storniert!")
        })
            .finally(() => {
                zeltlagerBookingContext.setLoading(false)
            })
    }

    const registerChildren = () => {
        if (validateBookingZeltlager(newBooking, children)) {
            let zeltlagerBooking = getZeltlagerBookingDTO(newBooking, children)
            zeltlagerBookingContext.createZeltlagerBooking(zeltlagerBooking).then(result => {
                setNewBooking({})
                setChildren(result.children!)
                let formState = getZeltlagerBookingFormState(result)
                setBooking(formState)
                SuccessNotification("Die Zeltlageranmeldung wurde erfolgreich gespeichert!")
                InformationNotification("Bitte lesen Sie Ihre E-Mails!!!")
            }).finally(() => {
                zeltlagerBookingContext.setLoading(false)
            })
        }

    }

    return (
        <div>
            <Container sx={{ paddingBottom: '4%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <br />
                <br />
                <br />
                <ZeltlagerTexte />
                <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    {
                        zeltlagerBookingContext.loading === true ? (<CircularProgress></CircularProgress>) :
                            (
                                auth.currentUser !== undefined && auth.currentUser !== null && zeltlager !== null && zeltlager !== undefined ?
                                    (
                                        <Container>

                                            {
                                                zeltlager.active ? (
                                                    <>
                                                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <h1>Zeltlager Daten generell</h1>
                                                        </Grid>
                                                        <br />
                                                        <Grid>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="center">Wann</TableCell>
                                                                            <TableCell align="center">Wo?</TableCell>
                                                                            <TableCell align="center">Preis (in Euro)</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            zeltlager.endDate !== undefined && zeltlager?.endDate !== null
                                                                                && zeltlager.startDate !== undefined && zeltlager.startDate !== null ? (<TableRow>
                                                                                    <TableCell align="center">{new Date(zeltlager.startDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })} - {new Date(zeltlager.endDate!).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" })}</TableCell>
                                                                                    <TableCell align="center"> {zeltlager.location}
                                                                                        <a href={zeltlager.locationLink} target="_blank" style={{ color: "green" }} rel="noreferrer">
                                                                                            <DirectionsIcon></DirectionsIcon>
                                                                                        </a>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">{zeltlager.price}</TableCell>

                                                                                </TableRow>) : null
                                                                        }

                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <br></br>
                                                            <p>Bei der Zahlung geben wir die Möglichkeit einer Preisminderung:
                                                                Unser regulärer Betrag für das Zeltlager pro Kind beträgt {zeltlager.price} Euro.
                                                            </p>
                                                            <p>
                                                                Für Geschwisterkinder gibt es eine Preisminderung von 5 Euro
                                                                Das bedeutet, dass das 1. Kind den normalen Betrag kostet und für jedes weitere Geschwisterkind 5 Euro abgezogen werden, somit würde das 2. Kind nur {zeltlager.price! - 5} Euro kosten.


                                                            </p>
                                                            <TextSplitter />
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    null
                                                )
                                            }

                                            {
                                                booking.id !== undefined ? (
                                                    <UpdateZeltlagerBooking
                                                        addChild={addChild}
                                                        checkBoxCarCapacityChecked={checkBoxCarCapacityChecked}
                                                        setCheckBoxCarCapacityChecked={setCheckBoxCarCapacityChecked}
                                                        children={children}
                                                        setChildren={setChildren}
                                                        setZeltlagerBooking={setBooking}
                                                        zeltlagerBooking={booking}
                                                        updateBooking={updateBooking}
                                                        zeltlager={zeltlager}
                                                        deleteBooking={deleteBooking}
                                                    ></UpdateZeltlagerBooking>
                                                ) : (
                                                    zeltlager.active === true ? (
                                                        <ZeltlagerAnmeldung
                                                            addChild={addChild}
                                                            checkBoxCarCapacityChecked={checkBoxCarCapacityChecked}
                                                            checkBoxRegistryChecked={checkBoxRegistryChecked}
                                                            children={children}
                                                            newBooking={newBooking}
                                                            registerChildren={registerChildren}
                                                            setCheckBoxCarCapacityChecked={setCheckBoxCarCapacityChecked}
                                                            setCheckBoxRegistryChecked={setCheckBoxRegistryChecked}
                                                            setChildren={setChildren}
                                                            setNewBooking={setNewBooking}
                                                        ></ZeltlagerAnmeldung>
                                                    ) : (<>
                                                        <h2>Die Anmeldung ist aktuell nicht aktiv.</h2>
                                                        <Button variant="contained" onClick={() => reload()} style={{ width: '200px' }}>Neu laden</Button>
                                                        <br></br>
                                                        <br></br>
                                                        <br></br>
                                                    </>)

                                                )
                                            }
                                            <br /><br />
                                        </Container>
                                    ) :
                                    (
                                        zeltlager.active === true ? (<CheckAnmeldung></CheckAnmeldung>)
                                            :
                                            (
                                                <>
                                                    <h2>Die Anmeldung ist aktuell nicht aktiv.</h2>
                                                    <Button variant="contained" onClick={() => reload()} style={{ width: '200px' }}>Neu laden</Button>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                </>
                                            )

                                    )
                            )
                    }
                </Container>
            </Container>
        </div >
    )
};
