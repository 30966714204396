import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { FC } from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export const TextVerwaltungAdmin: FC = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <>
            <br></br>
            <div>
                <h3>Text-Verwaltung</h3>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="Admintabs">
                            <Tab label="Home" {...a11yProps(0)} />
                            <Tab label="Zeltlager" {...a11yProps(1)} />
                            <Tab label="Nikolaus" {...a11yProps(2)} />
                            <Tab label="Spielewochenende" {...a11yProps(3)} />
                            
                        </Tabs>
                    </Box>
                    {/* <TabPanel value={value} index={0}>
                        <TextVerwaltungHome></TextVerwaltungHome>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TextVerwaltungZeltlager></TextVerwaltungZeltlager>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TextVerwaltungNikolaus></TextVerwaltungNikolaus>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <TextVerwaltungSpielewochenende></TextVerwaltungSpielewochenende>
                    </TabPanel>
                     */}
                </Box>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </>
    )
}