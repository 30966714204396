import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import React, { FC, useEffect } from "react"
import { ErrorNotification, SuccessNotification } from "../../configuration/notifications"
import { useEvent } from "../../context/EventContext"
import { EventDTO } from "../../models"
import TextSplitter from "../TextSplitter"


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export const EventsAdmin: FC = () => {
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(Date.now()));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(Date.now()))
    const [open, setOpen] = React.useState(false);

    const eventContext = useEvent();
    const [events, setEvents] = React.useState<EventDTO[]>([]);
    const [newEvent, setNewEvent] = React.useState<EventDTO>({ startDate: new Date() });

    function getTime(date?: Date) {
        return date !== undefined ? new Date(date).getTime() : 0;
    }

    useEffect(() => {
        eventContext.getEvents().then(result => {
            setEvents(result)
        }).catch(reason => {
            ErrorNotification(reason.statusCode, reason.message)
        }).finally(() => {
            eventContext.setLoading(false)
        });
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = (eventId: string) => {
        eventContext.deleteEvent(eventId).then(result => {
            setEvents(events.filter(event => event.id !== eventId));
            handleClose();
            SuccessNotification("Das Event wurde erfolgreich gelöscht!")
        }).catch(reason => {
            ErrorNotification(reason.statusCode, reason.message)
        }).finally(() => {
            eventContext.setLoading(false)
        });

    }

    const addEvent = () => {
        eventContext.createEvent(newEvent).then(result => {
            setEvents([...events, result])
            SuccessNotification("Ein Event wurde erfolgreich gespeichert!")
        }).catch(reason => {
            ErrorNotification(reason.statusCode, reason.message)
        }).finally(() => {
            eventContext.setLoading(false)
        })
    }

    return (
        <>
            <Container>
                <h2>Übersicht Termine</h2>
                {
                    eventContext.loading === true ? (<CircularProgress />) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>Datum</TableCell>
                                        <TableCell align='center'>Aktion</TableCell>
                                        <TableCell align='center'>Ort</TableCell>
                                        <TableCell align='center'></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        events.sort((a, b) => { return getTime(a.startDate) - getTime(b.startDate) }).map((event, key) => {
                                            return <TableRow key={key}>
                                                <TableCell align='center'>
                                                    {new Date(event.startDate!).toLocaleDateString("de-DE", {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                    })} {
                                                        new Date(event.startDate!).toLocaleTimeString("de-DE", {
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                        })
                                                    } Uhr -  {new Date(event.endDate!).toLocaleDateString("de-DE", {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                    })} {
                                                        new Date(event.endDate!).toLocaleTimeString("de-DE", {
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                        })
                                                    } Uhr
                                                </TableCell>
                                                <TableCell align='center'>{event.name}</TableCell>
                                                <TableCell align='center'>{event.location}</TableCell>
                                                <TableCell align='center'>
                                                    <Grid>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleClickOpen}
                                                        >
                                                            Löschen
                                                        </Button>

                                                        <Dialog
                                                            open={open}
                                                            TransitionComponent={Transition}
                                                            keepMounted
                                                            onClose={handleClose}
                                                            aria-describedby="deleteEvent"
                                                        >
                                                            <DialogTitle>{"Termin dauerhaft löschen?"}</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="deleteEvent">
                                                                    Termin wirklich löschen?
                                                                    Denk nochmal nach
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleClose}>Nein</Button>
                                                                <Button onClick={() => handleDelete(event.id!)}>Ja</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>)
                }
            </Container>
            <TextSplitter />
            <Container>

                <h2>Termin hinzufügen</h2>
                <br />
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    inputFormat="DD.MM.YYYY"
                                    label="Datum"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setNewEvent({ ...newEvent, startDate: newValue?.toDate() })
                                        setStartDate(newValue as Dayjs)
                                    }}

                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    inputFormat="DD.MM.YYYY"
                                    label="Datum"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setNewEvent({ ...newEvent, endDate: newValue?.toDate() })
                                        setEndDate(newValue as Dayjs)
                                    }}

                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                required
                                id="termin"
                                label="Aktion"
                                fullWidth
                                autoComplete="termin"
                                variant="outlined"
                                onChange={(newText) => {
                                    setNewEvent({ ...newEvent, name: newText.target.value });
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="duration"
                                label="Ort"
                                fullWidth
                                autoComplete="duration"
                                variant="outlined"
                                onChange={(newText) => {
                                    setNewEvent({ ...newEvent, location: newText.target.value })
                                }}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button variant="contained" onClick={() => addEvent()}>
                                Termin hinzufügen
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </>
    )
}