import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Slide, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC, useState } from "react";
import { DownloadDTO } from "../../models";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    download: DownloadDTO
    handleDownload: (id: string, password: string, fileName: string) => void
}

export const Download: FC<Props> = (props) => {

    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState<string>("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const download = () => {
        props.handleDownload(props.download.id!,password,props.download.fileName!)
        setPassword("")
        handleClose();
    }

    return (
        <>
            <Paper sx={{ padding: '3%', marginBottom: '4%' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <h3>{props.download.name}</h3>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={handleClickOpen}>Download</Button>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="closeDownload"
                    >
                        <DialogTitle>{"Herunterladen"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="downloadPictures">
                                Um die Bilder herunterladen zu können muss ein Passwort eingegeben werden!
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="password"
                                label="Passwort"
                                type="password"
                                value={password}
                                onChange={(value) => setPassword(value.target.value)}
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Abbrechen</Button>
                            <Button onClick={download}>Herunterladen</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Paper>
        </>
    );
}