import { Box, Button, Checkbox, Container, Grid, TextField } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import TextSplitter from "../../componets/TextSplitter";
import { AktionsChild } from "../../componets/Zeltlager/AktionsChild";
import { AktionChildDTO, SpielewochenendeBookingFormState } from "../../models";

interface Props {
    spielewochenendeBooking: SpielewochenendeBookingFormState
    setSpielewochenendeBooking: Dispatch<SetStateAction<SpielewochenendeBookingFormState>>
    children: AktionChildDTO[]
    setChildren: Dispatch<SetStateAction<AktionChildDTO[]>>
    checkBoxCarCapacityChecked: boolean,
    setCheckBoxCarCapacityChecked: Dispatch<SetStateAction<boolean>>
    addChild: () => void
    updateBooking: () => void
    deleteBooking: () => void
    disableButtons: boolean
}

export const UpdateSpielewochenendeBooking: FC<Props> = (props) => {
    return (
        <Container>
            <p>Hier können Sie nun Ihre Spielewochenende bearbeiten! Drücken Sie unbeding auf "Anmeldung aktualisieren", andernfalls werden die Änderungen nicht übernommen</p>
            <p>Bei Fragen wenden Sie sich bitte an website@pfarrjugendneusaess.de</p>
            <br></br>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="contained" onClick={props.deleteBooking}>Stornieren</Button>
            </div>
            <h4>Elternteil</h4>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        label="Vorname"
                        value={props.spielewochenendeBooking.parent?.firstName}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        onChange={(newText) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, parent: { ...props.spielewochenendeBooking.parent, firstName: newText.target.value } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastname"
                        label="Nachname"
                        value={props.spielewochenendeBooking.parent?.lastName}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        onChange={(newText) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, parent: { ...props.spielewochenendeBooking.parent, lastName: newText.target.value } })
                        }}
                    ></TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phoneNumber"
                        label="Telefonnumer"
                        value={props.spielewochenendeBooking.parent?.phoneNumber}
                        type="number"
                        fullWidth
                        autoComplete="phonenumber"
                        variant="outlined"
                        onChange={(newText) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, parent: { ...props.spielewochenendeBooking.parent, phoneNumber: newText.target.value } })
                        }}
                    ></TextField>
                </Grid>

                <Grid item xs={12} sm={2.5} marginTop={1}>
                    <Checkbox checked={props.checkBoxCarCapacityChecked} onChange={(value) => props.setCheckBoxCarCapacityChecked(value.target.checked)} />
                    Ich kann fahren
                </Grid>
                <Grid item xs={12} sm={3.5}>
                    <TextField
                        id="carCapacity"
                        label="Auto Kapazität"
                        type="number"
                        fullWidth
                        value={props.spielewochenendeBooking.parent?.carCapacity}
                        autoComplete="carcapacity"
                        variant="outlined"
                        disabled={!props.checkBoxCarCapacityChecked}
                        onChange={(newText) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, parent: { ...props.spielewochenendeBooking.parent, carCapacity: Number.parseInt(newText.target.value) } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        required
                        id="street"
                        label="Straße"
                        value={props.spielewochenendeBooking.address?.street}
                        fullWidth
                        autoComplete="street"
                        variant="outlined"
                        onChange={(value) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, address: { ...props.spielewochenendeBooking.address, street: value.target.value } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        required
                        id="houseNumber"
                        label="Hausnummer"
                        value={props.spielewochenendeBooking.address?.houseNumber}
                        fullWidth
                        autoComplete="houseNumber"
                        variant="outlined"
                        onChange={(value) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, address: { ...props.spielewochenendeBooking.address, houseNumber: value.target.value } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="plz"
                        label="Postleitzahl"
                        fullWidth
                        value={props.spielewochenendeBooking.address!.plz}
                        type="number"
                        autoComplete="plz"
                        variant="outlined"
                        onChange={(value) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, address: { ...props.spielewochenendeBooking.address, plz: Number.parseInt(value.target.value) } })
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="city"
                        label="Stadt"
                        value={props.spielewochenendeBooking.address?.city}
                        fullWidth
                        autoComplete="city"
                        variant="outlined"
                        onChange={(value) => {
                            props.setSpielewochenendeBooking({ ...props.spielewochenendeBooking, address: { ...props.spielewochenendeBooking.address, city: value.target.value } })
                        }}
                    ></TextField>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
                <Grid >
                    {
                        props.children.map((child,key) => {
                            return <AktionsChild children={props.children} setChildren={props.setChildren} child={child} keyOfChild={key} disableButtons={props.disableButtons}></AktionsChild>
                        })
                    }
                </Grid>
            </Grid>
            <br />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={props.addChild}>Kind hinzufügen</Button></Box>
            <TextSplitter />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}><Button variant="contained" onClick={props.updateBooking}>Anmeldung aktualisieren</Button></Box>
        </Container>
        )
}