import { Checkbox, Container, Typography } from '@mui/material'
import { style } from '@mui/system'
import React, { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router'
import TextSplitter from '../TextSplitter'

type Props = {
    checked: boolean,
    setCheckBox: Dispatch<SetStateAction<boolean>>,
    routing: boolean
}

export default function Datenschutz({ checked, setCheckBox, routing }: Props) {


    return (
        <Container>
            <Typography>
                Stimmen Sie den Rahmenbedingungen und den
                <a  href={"www.pfarrjugendneusaess.de/impressum"} target={"_blank"}> Datenschutz </a>
                zu!
                <br/>
                Ich bin mit den Rahmenbedingungen der Aktion der Pfarrjugend Neusäß einverstanden und habe alle angegebene Daten überprüft
                Außerdem darf die Pfarrjugend Neusäß im Sinne dieser Aktion auf die Daten zugreifen. Alle während der Aktion
                gesammelten Daten werden ein Monat nach der Aktion aus unserem System gelöscht. Ihr Benutzerkonto bleibt bestehen,
                sodass Sie sich im nächsten Jahr oder bei anderen Aktionen der Pfarrjugend Neusäß damit wieder anmelden können.
            </Typography>
            <br></br>
            {
                routing ? (
                    <>
                        <p>Damit eine optimale Route zwischen den Termine herausgefunden werden kann, schicken wir Ihre Adresse (Straße, Hausnummer, Postleitzahl und Stadt/Ort) an "Google Maps". Es ist möglich, dass der Server von Google Maps dabei in den USA steht. Dabei werden keine
                            weiteren Daten von Ihnen erfasst.
                        </p>
                        <br></br>
                    </>
                ) : (null)
            }

            <Checkbox checked={checked} onChange={(value) => setCheckBox(value.target.checked)} />
            Ich bin damit einverstanden
            <br></br>
        </Container>
    )
}
