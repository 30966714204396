import { Grid, TextField } from '@mui/material'
import React from 'react'
import { useAuth } from '../../context/AuthContext';
import { NikolausBookingFormState } from '../../models';

type Props = {
    setBooking: React.Dispatch<React.SetStateAction<NikolausBookingFormState>>;
    booking:  NikolausBookingFormState;
    disableButtons: boolean
}

export default function NikolausDaten({setBooking, booking, disableButtons}: Props) {
    const auth = useAuth();
  return (
    <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="firstName"
                                    label="Vorname"
                                    fullWidth
                                    disabled={disableButtons}
                                    value={booking.firstName}
                                    autoComplete="given-name"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, firstName: value.target.value })
                                        }
                                    }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="lastname"
                                    disabled={disableButtons}
                                    label="Nachname"
                                    fullWidth
                                    value={booking.lastName}
                                    autoComplete="family-name"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, lastName: value.target.value })
                                        }
                                    }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    required
                                    id="street"
                                    label="Straße"
                                    disabled={disableButtons}
                                    fullWidth
                                    value={booking.street}
                                    autoComplete="street"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, street: value.target.value })
                                        }
                                    }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    id="houseNumber"
                                    label="Hausnummer"
                                    disabled={disableButtons}
                                    fullWidth
                                    value={booking.houseNumber}
                                    autoComplete="houseNumber"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, houseNumber: value.target.value })
                                        }
                                    }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    id="plz"
                                    label="Postleitzahl"
                                    disabled={disableButtons}
                                    fullWidth
                                    type="number"
                                    value={booking.plz}
                                    autoComplete="plz"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, plz: Number.parseInt(value.target.value)})
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { min: 0 }
                                      }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    id="city"
                                    label="Stadt"
                                    disabled={disableButtons}
                                    fullWidth
                                    value={booking.city}
                                    autoComplete="city"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, city: value.target.value })
                                        }
                                    }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    id="phoneNumber"
                                    label="Telefonnumer"
                                    disabled={disableButtons}
                                    fullWidth
                                    value={booking.phoneNumber}
                                    autoComplete="phonenumber"
                                    onChange={(value) => {
                                        if (!!value) {
                                            setBooking({ ...booking, phoneNumber: value.target.value })
                                        }
                                    }}
                                    variant="outlined"></TextField>
                            </Grid>
                            <Grid item xs={6}>
                                
                            </Grid>
                        </Grid>
  )
}